import React from 'react'
import PropTypes from 'prop-types'
import { Autocomplete as MuiAutocomplete, TextField } from '@mui/material'
import { Controller } from 'react-hook-form'

const Autocomplete = ({ control, name, label, ...rest }) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { onChange, value }, fieldState }) => (
      <MuiAutocomplete
        {...rest}
        onChange={(event, values) => onChange(values)}
        value={value}
        renderInput={(params) => <TextField {...params} label={label} error={!!fieldState.error} helperText={fieldState.error ? fieldState.error.message : ''}/>}
      />
    )}
  />
)

Autocomplete.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
}

Autocomplete.defaultProps = {

}

export default Autocomplete
