import { makeStyles } from '@mui/styles'

const getHomeTeamInputBackground = (homeTeamScore, awayTeamScore) => {
  let homeTeamInputBackground = '#ffffff'
  if (homeTeamScore !== '' && awayTeamScore !== '') {
    homeTeamInputBackground = '#c2edcf'
  }
  return homeTeamInputBackground
}

const getAwayTeamInputBackground = (homeTeamScore, awayTeamScore) => {
  let awayTeamInputBackground = '#ffffff'
  if (homeTeamScore !== '' && awayTeamScore !== '') {
    awayTeamInputBackground = '#c2edcf'
  }
  return awayTeamInputBackground
}

export const useStyles = makeStyles(() => ({
  container: {
    display: 'flex'
  },
  homeTeamInput: {
    fontSize: '1em',
    width: '2em',
    margin: '0 5px',
    background: props => getHomeTeamInputBackground(props.homeTeamScore, props.awayTeamScore),
    '& .MuiInputBase-input': {
      padding: '14px 0',
      textAlign: 'center'
    }
  },
  awayTeamInput: {
    fontSize: '1em',
    width: '2em',
    margin: '0 5px',
    background: props => getAwayTeamInputBackground(props.homeTeamScore, props.awayTeamScore),
    // background: props => props
    '& .MuiInputBase-input': {
      padding: '14px 0',
      textAlign: 'center'
    }
  }
}))