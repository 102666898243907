import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Container, Divider, List, ListItem, ListItemText, Typography } from '@mui/material'

import { useGetCompetitions } from 'queries'

import { useStyles } from './AdminPage.styles'

const AdminPage = () => {
  const navigate = useNavigate()
  const classes = useStyles()
  const [competitions, loading] = useGetCompetitions()
  return (
    <div className={classes.pageContainer}>
      <Typography component="h1" variant="h4" className={classes.pageTitle}>
        Admin
      </Typography>
      <Container>
        {!loading && competitions.length > 0 &&
          <List className={classes.adminList}>
            {competitions.map((competition, index) => (
              <React.Fragment key={`competition-item-${competition.id}`}>
                <ListItem
                  button
                  onClick={() => navigate(`/admin/competition/${competition.id}`)}
                  className={classes.adminListItem}
                >
                  <ListItemText
                    primary={competition.name}
                  />
                </ListItem>
                {index < competitions.length - 1 && <Divider />}
              </React.Fragment>
            ))}
          </List>
        }
      </Container>
    </div>
  )
}

export default AdminPage
