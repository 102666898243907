import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { LoginDialog } from 'pages/login/components'
import { useApp, useQuery, useUser } from 'hooks'

const LoginPage = () => {
  const navigate = useNavigate()
  const query = useQuery()
  const { showAlert } = useApp()
  const { isLoggedIn, userName, email, pools } = useUser()

  useEffect(() => {
    if (isLoggedIn) {
      showAlert({ message: `Welcome ${userName || email}!` })
      if (query.get('next')) {
        navigate(query.get('next'))
      } else if (pools && pools.length === 1) {
        navigate(`/pool/${pools[0]}`)
      } else {
        navigate('/pools')
      }
    }
  }, [isLoggedIn])


  if (isLoggedIn) {
    return null
  }

  return (
    <LoginDialog
      open
      onRegisterClick={() => navigate(`/register?${query.toString()}`)}
    />
)}

export default LoginPage
