import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useApp, useUser } from 'hooks'

export const RequireAuth = ({ children, redirectTo }) => {
  const { isLoggedIn, loading } = useUser()
  const location = useLocation()

  if (!loading) {
    if (!isLoggedIn) {
      return <Navigate to={`${redirectTo || '/login'}?next=${location.pathname}`} />
    }
    return children
  }
  return null
}

export const RequireInitialized = ({ children }) => {
  const { loading } = useUser()
  if (!loading) {
    return children
  }
  return null
}

export const RequireAdmin = ({ children, redirectTo }) => {
  const { isLoggedIn, isAdmin, loading } = useUser()
  const location = useLocation()
  const { showAlert } = useApp()
  if (!loading) {
    if (!isLoggedIn) {
      return <Navigate to={`${redirectTo || '/login'}?next=${location.pathname}`} />
    }
    if (!isAdmin) {
      showAlert({ message: 'Invalid permissions', type: 'error' })
      return <Navigate to="/" />
    }
    return children
  }
  return null
}