import { query, where, getDocs } from 'firebase/firestore'
import { collection } from 'firebase-client/client'
import { hasValidResult } from 'utils/fixture'

const processFixture = (data) => {
  const fixture = { ...data }
  if (data.date) {
    const date = data.date.toDate()
    const now = new Date()
    fixture.status = 'scheduled'
    if (hasValidResult(data)) {
      fixture.status = 'finished'
    } else if (date - now < 0) {
      fixture.status = 'started'
    }
  }
  return fixture
}

export const getCompetitionFixtures = async (competitionId) => {
  const q = query(collection("fixtures"), where("competitionId", "==", competitionId))
  const querySnapshot = await getDocs(q)
  return querySnapshot.docs.map((doc) => ({ id: doc.id, ...processFixture(doc.data())}))
}
