import React, { useState } from 'react'
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import { IoClose } from 'react-icons/io5'

import { AddPoolForm } from 'pages/pools/components'
import { useApp, useUser } from 'hooks'
import { useGetCompetitions } from 'queries'
import { addPool } from 'mutations'
import AdminFixtureForm from './AdminFixtureForm'

export const addPoolGeneralErrorCodes = []
export const addPoolNameErrorCodes = []
export const addPoolCompetitionErrorCodes = []

const AdminFixtureDialog = ({
  open,
  setDialogOpen,
  closable = true,
  ...formProps
}) => (
    <Dialog
      open={open}
      onClose={() => closable && setDialogOpen && setDialogOpen(false)}
      aria-labelledby="add-dialog-title"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="add-dialog-title">
        {formProps.fixture ? 'Edit Fixture' : 'Add Fixture'}
        {closable && setDialogOpen && (
          <div
            aria-label="close"
            onClick={() => setDialogOpen(false)}
            style={{
              position: 'absolute',
              right: 10,
              top: 10,
              cursor: 'pointer',
            }}
          >
            <IoClose />
          </div>
        )}
      </DialogTitle>
      <DialogContent>
        <AdminFixtureForm {...formProps}/>
      </DialogContent>
    </Dialog>
  )

export default AdminFixtureDialog
