import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  container: {
    display: 'flex'
  },
  homeTeamScore: {
    fontSize: '1.2em',
    fontWeight: 'bold',
    width: '2em',
    height: '51px',
    lineHeight: '51px',
    margin: '0 5px',
    position: 'relative',
  },
  divider: {
    position: 'absolute',
    height: '2px',
    width: '10px',
    background: '#000000',
    left: '100%',
    top: 'calc(50% - 1px)',
    zIndex: 1
  },
  awayTeamScore: {
    fontSize: '1.2em',
    fontWeight: 'bold',
    width: '2em',
    height: '51px',
    lineHeight: '51px',
    margin: '0 5px'
  },
  predictionContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 5,
    "@media (max-width: 767px)": {
      bottom: 10
    }
  },
  predictionHomeTeamScore: {
    fontSize: '0.7em',
    fontWeight: 'bold',
    width: '2em',
    height: '20px',
    lineHeight: '20px',
    margin: '0 5px',
    position: 'relative',
  },
  predictionDivider: {
    position: 'absolute',
    height: '1px',
    width: '5px',
    background: '#000000',
    left: 'calc(100% + 2.5px)',
    top: 'calc(50% - 1px)',
    zIndex: 1
  },
  predictionAwayTeamScore: {
    fontSize: '0.7em',
    fontWeight: 'bold',
    width: '2em',
    height: '20px',
    lineHeight: '20px',
    margin: '0 5px'
  },
}))