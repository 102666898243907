import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  Switch,
  Tab,
  Tabs,
  Typography,
  BottomNavigation,
  BottomNavigationAction,
  Paper,
  useMediaQuery,
} from '@mui/material'
import { MdOutlineChecklistRtl } from 'react-icons/md'
import { FiUsers } from 'react-icons/fi'
import { IoSettings, IoTrophyOutline } from 'react-icons/io5'

import { Share } from 'components/ui'

import { useStyles } from './PoolPage.styles'
import { useApp, useUser, usePool } from 'hooks'
import SettingsSectionForm from './components/SettingsSectionForm'
import StandingsTable from './components/StandingsTable'
import { AiOutlineUser } from 'react-icons/ai'
import PredictionsSection from './components/PredictionsSection'
import WebFont from 'webfontloader'
import UsersTable from './components/UsersTable'
import {
  updatePageTitle,
  updatePageDescription,
  updatePageOgURL,
} from 'utils/seo'

const a11yProps = (index, tab = true) => {
  if (tab) {
    return {
      id: `pool-tab-${index}`,
      'aria-controls': `pool-tabpanel-${index}`,
    }
  }
  return {
    id: `pool-tabpanel-${index}`,
    'aria-labelledby': `pool-tab-${index}`,
  }
}
const TabPanel = ({ children, value, index, ...other }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    {...a11yProps(index, false)}
    {...other}
  >
    {value === index && <Box p={0}>{children}</Box>}
  </div>
)

const Pool = () => {
  const { poolId } = useParams()
  const [tab, setTab] = useState(0)
  const [showPredictionsPerFixture, setShowPredictionsPerFixture] = useState(true)
  const classes = useStyles()
  const { showAlert } = useApp()
  const { id } = useUser()
  const isMobile = useMediaQuery('(max-width:767px)')
  const { pathname } = useLocation()

  const { pool, users, loaded } = usePool()
  const isOwner = pool?.ownerUserId === id

  const pages = [
    {
      name: 'Predictions',
      icon: <MdOutlineChecklistRtl style={{ fontSize: 26 }} />,
    },
    { name: 'Standings', icon: <IoTrophyOutline style={{ fontSize: 26 }} /> },
    { name: 'Settings', icon: <IoSettings style={{ fontSize: 26 }} /> },
  ]

  if (isOwner) {
    pages.push({ name: 'Users', icon: <FiUsers style={{ fontSize: 26 }} /> })
  }

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Kanit:400,700', 'Roboto:400,700'],
      },
    })
  }, [])

  useEffect(() => {
    if (pool) {
      updatePageTitle(pathname, pool)
      updatePageDescription(pathname, pool)
      updatePageOgURL(pathname)
    }
  }, [pool])

  const handleTabChange = (event, newValue) => {
    setTab(newValue)
    window.scrollTo(0, 0)
  }

  const onShareSuccess = () => {
    showAlert({ message: `Your link was copied to clipboard. Get sending!` })
  }

  if (!pool || !loaded) {
    return null
  }

  return (
    <div className={classes.pageContainer}>
      <div className={classes.headerContainer}>
        <Typography component="h1" variant="h3" className={classes.pageTitle}>
          {pool.name}
        </Typography>
        {!isMobile && (
          <div className={classes.headerTop}>
            <Tabs
              className={classes.poolTabs}
              value={tab}
              textColor="secondary"
              indicatorColor="secondary"
              onChange={handleTabChange}
              aria-label="Pool Pages"
              centered
            >
              {pages.map((t, i) => (
                <Tab
                  key={`pages-tab-${t.name}`}
                  style={{
                    fontSize: '0.875rem',
                    letterSpacing: '1.2px',
                    fontWeight: 700,
                    margin: '0 10px',
                  }}
                  label={t.name}
                  icon={t.icon}
                  // iconPosition="start"
                  {...a11yProps(i)}
                />
              ))}
            </Tabs>
          </div>
        )}
      </div>
      <div className={classes.poolUsers} style={{ display: 'none' }}>
        <AvatarGroup max={2}>
          {users.map(u => (
            <Avatar alt={u.userName} key={u.id}>
              <AiOutlineUser />
            </Avatar>
          ))}
        </AvatarGroup>
        {isOwner && (
          <Share
            dialogTitle="Invite to Pool"
            url={`${window.location.origin}/join/${poolId}`}
            onSuccess={onShareSuccess}
          >
            <Button variant="contained" size="large" style={{ marginLeft: 12 }}>
              Invite
            </Button>
          </Share>
        )}
      </div>
      <div className={classes.content}>
        <TabPanel
          value={tab}
          index={0}
          style={{ minHeight: 'calc(100vh - 252px)' }}
        >
          <PredictionsSection />
        </TabPanel>
        <TabPanel
          value={tab}
          index={1}
          style={{ minHeight: 'calc(100vh - 252px)' }}
        >
          <Typography
            component="h4"
            variant="h4"
            className={classes.pageSubtitle}
          >
            Standings
          </Typography>
          <Button
              onClick={() => setShowPredictionsPerFixture(!showPredictionsPerFixture)}
              variant="contained"
            >
              {showPredictionsPerFixture ? 'View Score Overview' : 'View Match Predictions'}
          </Button>
          {pool.approvedUsers?.length > 0 && (
            <StandingsTable
              users={users.filter(u => pool.approvedUsers?.includes(u.id))}
              showPredictionsPerFixture={showPredictionsPerFixture}
            />
          )}
          {(!pool.approvedUsers ||
            pool.approvedUsers.length < users.length) && (
            <>
              <Typography
                component="h6"
                variant="h6"
                style={{ color: '#2e2e2e', marginTop: 50 }}
              >
                Spectators
              </Typography>
              <StandingsTable
                users={users.filter(u => !pool.approvedUsers?.includes(u.id))}
                showPredictionsPerFixture={showPredictionsPerFixture}
                version="basic"
              />
            </>
          )}
          <br />
          <br />
        </TabPanel>
        <TabPanel
          value={tab}
          index={2}
          style={{ minHeight: 'calc(100vh - 252px)' }}
        >
          <Typography
            component="h4"
            variant="h4"
            className={classes.pageSubtitle}
          >
            Settings
          </Typography>
          <SettingsSectionForm />
        </TabPanel>
        <TabPanel
          value={tab}
          index={3}
          style={{ minHeight: 'calc(100vh - 252px)' }}
        >
          <Typography
            component="h4"
            variant="h4"
            className={classes.pageSubtitle}
          >
            Users
          </Typography>
          {isOwner && (
            <Share
              dialogTitle="Invite to Pool"
              url={`${window.location.origin}/join/${poolId}`}
              onSuccess={onShareSuccess}
            >
              <Button
                variant="contained"
                size="large"
                style={{ marginLeft: 12 }}
              >
                Invite
              </Button>
            </Share>
          )}
          <UsersTable />
        </TabPanel>
      </div>
      {isMobile && (
        <Paper
          sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 2 }}
          elevation={3}
        >
          <BottomNavigation
            showLabels
            value={tab}
            onChange={handleTabChange}
            style={{ height: 64, background: '#10303E', color: '#ffffff' }}
          >
            <BottomNavigationAction
              label="Predictions"
              icon={
                <MdOutlineChecklistRtl
                  className={classes.bottomNavigationActionIcon}
                />
              }
              className={classes.bottomNavigationAction}
            />
            <BottomNavigationAction
              label="Standings"
              icon={
                <IoTrophyOutline
                  className={classes.bottomNavigationActionIcon}
                />
              }
              className={classes.bottomNavigationAction}
            />
            <BottomNavigationAction
              label="Settings"
              icon={
                <IoSettings className={classes.bottomNavigationActionIcon} />
              }
              className={classes.bottomNavigationAction}
            />
            {isOwner && (
              <BottomNavigationAction
                label="Users"
                icon={
                  <FiUsers className={classes.bottomNavigationActionIcon} />
                }
                className={classes.bottomNavigationAction}
              />
            )}
          </BottomNavigation>
        </Paper>
      )}
    </div>
  )
}

export default Pool
