import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Button, Box, Menu, MenuItem, Avatar } from '@mui/material'

import { useApp, useUser } from 'hooks'
import { logout } from 'mutations'

const TopNav = () => {
  const { loading, isLoggedIn, isAdmin, photoURL } = useUser()
  const navigate = useNavigate()
  const { showAlert } = useApp()
  const { pathname } = useLocation()
  const [accountMenuAnchorEl, setAccountMenuAnchorEl] = useState(null)
  const accountMenuOpen = Boolean(accountMenuAnchorEl)

  const onAccountClick = () => {
    setAccountMenuAnchorEl(null)
    navigate('/account')
  }

  const onLogout = () => {
    setAccountMenuAnchorEl(null)
    logout()
    navigate('/')
    showAlert({ message: 'You have been logged out!' })
  }

  if (loading || pathname.indexOf('/join/') >= 0) {
    return null
  }

  return (
    <Box
      sx={{
        display: { xs: 'none', sm: 'flex' },
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {isLoggedIn ? (
        <>
          {isAdmin && (
            <>
              <Button color="inherit" onClick={() => navigate('/admin')}>
                Admin
              </Button>
              <span style={{ color: '#ffffff' }}>|</span>
            </>
          )}
          <Button color="inherit" onClick={() => navigate('/pools')}>
            My Pools
          </Button>
          <span style={{ color: '#ffffff' }}>|</span>
          <>
            <div
              color="inherit"
              id="account-button"
              aria-controls={accountMenuOpen ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={accountMenuOpen ? 'true' : undefined}
              onClick={event => setAccountMenuAnchorEl(event.currentTarget)}
              style={{ paddingLeft: 20, cursor: 'pointer' }}
            >
              <Avatar src={photoURL} sx={{ width: 35, height: 35 }} />
            </div>
            <Menu
              id="account-menu"
              anchorEl={accountMenuAnchorEl}
              open={accountMenuOpen}
              onClose={() => setAccountMenuAnchorEl(null)}
              MenuListProps={{
                'aria-labelledby': 'account-button',
              }}
            >
              <MenuItem onClick={onAccountClick}>Account</MenuItem>
              <MenuItem onClick={onLogout}>Logout</MenuItem>
            </Menu>
          </>
        </>
      ) : (
        <>
          <Button color="inherit" onClick={() => navigate('/login')}>
            Login
          </Button>
          <span style={{ color: '#ffffff' }}>|</span>
          <Button color="inherit" onClick={() => navigate('/register')}>
            Register
          </Button>
        </>
      )}
    </Box>
  )
}

export default TopNav
