import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'
import { getFirestore, collection as firebaseCollection, doc as firebaseDoc } from 'firebase/firestore'
import { firebaseConfig } from '../config'

export const firebaseApp = initializeApp(firebaseConfig)

export const firebaseAuth = getAuth(firebaseApp)

export const firestore = getFirestore(firebaseApp)

const converter = {
    toFirestore(post) {
        return post
    },
    fromFirestore(snapshot, options) {
        const data = snapshot.data(options)
        return {
            id: snapshot.id,
            ...data
        }
    },
}

export const collection = (name) => firebaseCollection(firestore, name).withConverter(converter)

export const doc = (name, ...props) => firebaseDoc(firestore, name, ...props).withConverter(converter)
