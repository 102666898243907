import React from 'react'
import { createRoot } from 'react-dom/client'
import { ThemeProvider } from '@mui/material/styles'
import { BrowserRouter, Routes, Route } from 'react-router-dom'

import { AppProvider, AuthProvider, PoolProvider } from 'hooks/providers'
import { HomePage, LoginPage, RegisterPage, PoolsPage, PoolPage, JoinPage, PrivacyPage, AdminPage, AdminCompetitionPage, AccountPage } from 'pages'
import { theme } from 'themes'
import { Layout } from 'components/layout'
import { RequireAuth, RequireInitialized, RequireAdmin } from 'utils/routes'
import { StyledEngineProvider } from '@mui/material'

import './index.css'

if (typeof navigator.serviceWorker !== 'undefined' && process.env.PUBLIC_URL) {
  navigator.serviceWorker.register('./service-worker.js')
}

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <AppProvider>
        <AuthProvider>
          <BrowserRouter>
            <Layout>
              <Routes>
                <Route path="/" element={<RequireInitialized><HomePage/></RequireInitialized>}/>
                <Route path="/login" element={<RequireInitialized><LoginPage/></RequireInitialized>}/>
                <Route path="/register" element={<RequireInitialized><RegisterPage/></RequireInitialized>}/>
                <Route path="/pools" element={<RequireAuth><PoolsPage/></RequireAuth>}/>
                <Route path="/account" element={<RequireAuth><AccountPage/></RequireAuth>}/>
                <Route path="/pool/:poolId" element={<RequireAuth><PoolProvider><PoolPage/></PoolProvider></RequireAuth>}/>
                <Route path="/join/:poolId" element={<RequireInitialized><PoolProvider><JoinPage/></PoolProvider></RequireInitialized>}/>
                <Route path="/privacy" element={<PrivacyPage/>}/>
                <Route path="/admin" element={<RequireAdmin><AdminPage/></RequireAdmin>}/>
                <Route path="/admin/competition/:competitionId" element={<RequireAdmin><AdminCompetitionPage/></RequireAdmin>}/>
              </Routes>
            </Layout>
          </BrowserRouter>
        </AuthProvider>
      </AppProvider>
    </ThemeProvider>
  </StyledEngineProvider>
)
