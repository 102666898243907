import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Button, Container, Typography } from '@mui/material'

import { getCompetitionFixtures, getTeams, useGetCompetitions } from 'queries'

import { useStyles } from './AdminCompetitionPage.styles'
import Fixture from 'pages/pool/components/Fixture'
import AdminFixtureDialog from './components/AdminFixtureDialog'

const AdminCompetitionPage = () => {
  const { competitionId } = useParams()
  const classes = useStyles()
  const [competitions, loading] = useGetCompetitions()
  const [fixtures, setFixtures] = useState([])
  const [teams, setTeams] = useState([])
  const [dialogOpen, setDialogOpen] = useState(false)
  const [dialogFixture, setDialogFixture] = useState()

  const competition = !loading && competitions && competitions.find(c => c.id === competitionId)

  useEffect(() => {
    Promise.allSettled([getTeams(), getCompetitionFixtures(competitionId)]).then(([teamsResult, fixturesResult]) => {
        const teams = teamsResult.status === 'fulfilled' ? teamsResult.value : []
        const fixtures = fixturesResult.status === 'fulfilled' ? fixturesResult.value : []
        setTeams(teams)
        setFixtures(fixtures.map((f) => ({
          ...f,
          homeTeam: teams.find(t => t.id === f.homeTeam.id),
          awayTeam: teams.find(t => t.id === f.awayTeam.id),
        })))
      })
  }, [])

  const onFixtureClick = (fixture) => {
    setDialogFixture(fixture)
    setDialogOpen(true)
  }

  const onAddFixture = () => {
    setDialogFixture()
    setDialogOpen(true)
  }

  const onFixtureDialogSuccess = (fixture) => {
    const found = fixtures.find(f => f.id === fixture.id)
    if (found) {
      setFixtures(fixtures.map(f => found.id === f.id ? {
        ...fixture,
        homeTeam: teams.find(t => t.id === f.homeTeam.id),
        awayTeam: teams.find(t => t.id === f.awayTeam.id),
      } : f))
    } else {
      setFixtures([...fixtures, { ...fixture, homeTeam: teams.find(t => t.id === fixture.homeTeam.id), awayTeam: teams.find(t => t.id === fixture.awayTeam.id)}])
    }
    setDialogOpen(false);
  }

  if (!competition) {
    return null
  }

  return (
    <div className={classes.pageContainer}>
      <Typography component="h1" variant="h4" className={classes.pageTitle}>
        {competition.name}
      </Typography>
      <div>
        <Button onClick={onAddFixture} variant="contained">Add Fixture</Button>
        <br/>
        <br/>
      </div>
      <Container>
        <div className={classes.fixtures}>
          {fixtures?.sort((a, b) => a.date.seconds - b.date.seconds).map(fixture => (
            <div key={`fixture-${fixture.id}`} className={classes.fixture} onClick={() => onFixtureClick(fixture)}>
              <Fixture fixture={fixture}/>
            </div>
          ))}
        </div>
      </Container>
      <AdminFixtureDialog open={dialogOpen} setDialogOpen={setDialogOpen} teams={teams} competition={competition} fixture={dialogFixture} onSuccess={onFixtureDialogSuccess}/>
    </div>
  )
}

export default AdminCompetitionPage
