
import React from 'react'
import { hasValidResult } from 'utils/fixture'
import { useStyles } from './FixtureResult.styles'

const FixtureResult = ({ fixture, showPrediction = false }) => {
  const classes = useStyles()
  const { prediction, homeTeamScore, awayTeamScore } = fixture

  return (
    <div className={classes.container}>
      <span className={classes.homeTeamScore}>{homeTeamScore}<span className={classes.divider}/></span>
      <span className={classes.awayTeamScore}>{awayTeamScore}</span>
      {showPrediction ?
        <div className={classes.predictionContainer}>
          {hasValidResult(prediction) ?
            <>
              <span className={classes.predictionHomeTeamScore}>{prediction.homeTeamScore}<span className={classes.predictionDivider}/></span>
              <span className={classes.predictionAwayTeamScore}>{prediction.awayTeamScore}</span>
            </>
          :
            <span style={{ fontSize: 10, textAlign: 'center' }}>No prediction</span>
          }
        </div>
      :
        <div className={classes.predictionContainer}>
          <span style={{ fontSize: 10, textAlign: 'center' }}>{fixture.status}</span>
        </div>
      }
    </div>
  )
}

export default FixtureResult