/* eslint-disable no-unused-expressions */
import React, { useState, useRef, createContext } from 'react'

export const AppContext = createContext()

export const AppProvider = ({ children }) => {
  const timeout = useRef()
  const [alert, setAlert] = useState()
  const [alertOpen, setAlertOpen] = useState(false)

  const showAlert = payload => {
    timeout.current && clearTimeout(timeout)
    setAlert(payload)
    setAlertOpen(true)
    if (!payload.preventAutoClose) {
      timeout.current = setTimeout(() => {
        setAlertOpen(false)
        setAlert()
      }, 5000)
    }
  }

  const closeAlert = () => {
    setAlertOpen(false)
    setAlert()
  }

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <AppContext.Provider value={{ alert, showAlert, closeAlert, alertOpen }}>
      {children}
    </AppContext.Provider>
  )
}
