import React, { useState } from 'react'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { Avatar, Button, Typography } from '@mui/material'
import { useDropzone } from 'react-dropzone'
import { ImUser } from 'react-icons/im'
import { FaCamera } from 'react-icons/fa'

import { Input } from 'components/form'
import { readUploadedImage, resizeUploadedImage } from 'utils/image'

import { updateUser } from 'mutations'
import { useApp, useUser } from 'hooks'

import { useStyles } from './AccountPage.styles'

const schema = Yup.object({
  userName: Yup.string()
    .required('required'),
}).required()

const AccountPage = () => {
    const { showAlert } = useApp()
  const classes = useStyles()
  const { id, userName, photoURL, email } = useUser()
  const [userPhoto, setUserPhoto] = useState({ photoURL });

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: {
      'image/*': ['.png', '.jpeg', '.jpg']
    },
    onDrop: acceptedFiles => {
      const imageFile = acceptedFiles[0];
      const image = URL.createObjectURL(imageFile);
      const processUploadedImage = (data) => {
        setUserPhoto({
          photoURL: image,
          photo: data,
          photoFileName: imageFile.name
        });
      }
      try {
        resizeUploadedImage(imageFile, 200, processUploadedImage);
      } catch (error) {
        readUploadedImage(imageFile, processUploadedImage);
      }
    }
  });

  const { control, handleSubmit } = useForm({
    defaultValues: { userName, email },
    resolver: yupResolver(schema),
  })

  const onSubmit = values => {
    updateUser(id, { userName: values.userName, ...userPhoto }).then(() => {
      showAlert({ message: 'User settings have been saved!' })
      window.location.reload()
    })
  }

  return (
    <div className={classes.pageContainer}>
      <Typography component="h1" variant="h4" className={classes.pageTitle}>
        Account Settings
      </Typography>
      <div className={classes.container}>
        <div className={classes.userImage}>
          {userPhoto.photoURL &&
            <Avatar src={userPhoto.photoURL} className={classes.userImageAvatar} onClick={open}/>
          }
          <div className={classes.dropzoneContent} {...getRootProps()} style={{ display: !userPhoto.photoURL ? 'flex' : 'none' }}>
            <input {...getInputProps()} />
            <ImUser className={classes.dropzoneIcon} />
            <FaCamera className={classes.dropzoneIconEdit} />
          </div>
        </div>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <Input
            name="email"
            label="Email"
            control={control}
            className={classes.formInput}
            disabled
          />
          <Input
            name="userName"
            label="User Name"
            control={control}
            className={classes.formInput}
          />
          <div className={classes.button}>
            <Button variant="contained" size="large" type="submit">
              Save
            </Button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default AccountPage
