import React from 'react'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button } from '@mui/material'
import { useForm } from 'react-hook-form'

import { registerWithEmailAndPassword } from 'mutations'
import { GeneralError, Input } from 'components/form'
import { useNavigate } from 'react-router-dom'

const authRegisterEmailErrorCodes = [
  'auth/email-already-in-use',
  'auth/invalid-email',
  'auth/credential-already-in-use',
  'auth/account-exists-with-different-credential',
]
const authRegisterPasswordErrorCodes = ['auth/invalid-password']

const prettyErrorMessage = error => {
  const { code, message } = error
  if (code === 'auth/invalid-password') {
    return 'Password must be at least 10 characters'
  }
  if (
    code === 'auth/email-already-in-use' ||
    code === 'auth/credential-already-in-use' ||
    code === 'auth/account-exists-with-different-credential'
  ) {
    return 'There is already an account with that email'
  }
  if (code === 'auth/invalid-email') {
    return 'Email is invalid'
  }
  if (code === 'auth/too-many-requests') {
    return 'Too many requests right now'
  }
  if (code === 'auth/network-request-failed') {
    return 'Network request failed'
  }
  if (code === 'auth/timeout') {
    return 'Network request timed out'
  }
  return message
}

const schema = Yup.object({
  emailAddress: Yup.string()
    .email('Email address is not valid')
    .required('Email address is required'),
  password: Yup.string()
    .min(10, 'Password must be at least 10 characters')
    .required('Password is required'),
  userName: Yup.string().required('Password is required'),
}).required()

const RegisterForm = () => {
  const navigate = useNavigate()
  const { control, handleSubmit, setError } = useForm({
    defaultValues: {
      emailAddress: '',
      password: '',
      userName: '',
    },
    resolver: yupResolver(schema),
  })

  const onSubmit = async formValues => {
    const { emailAddress, password, userName } = formValues
    const { error } = await registerWithEmailAndPassword(
      userName,
      emailAddress,
      password,
    )

    if (error) {
      if (authRegisterEmailErrorCodes.includes[error.code]) {
        setError('emailAddress', {
          type: 'SERVER_ERROR',
          message: prettyErrorMessage(error),
        })
        return
      }
      if (authRegisterPasswordErrorCodes.includes[error.code]) {
        setError('password', {
          type: 'SERVER_ERROR',
          message: prettyErrorMessage(error),
        })
        return
      }
      setError('generalError', {
        type: 'SERVER_ERROR',
        message: prettyErrorMessage(error),
      })
    } else {
      navigate(0)
    }
  }

  return (
    <form onSubmit={handleSubmit(values => onSubmit(values))}>
      <Input
        name="userName"
        label="Սsername"
        control={control}
        fullWidth
        required
      />
      <br />
      <br />
      <Input
        name="emailAddress"
        label="Email address"
        autoComplete="email"
        control={control}
        required
        fullWidth
      />
      <br />
      <br />
      <Input
        name="password"
        label="Password"
        type="password"
        required
        control={control}
        fullWidth
      />
      <br />
      <br />
      <GeneralError control={control} />
      <div style={{ textAlign: 'center', marginTop: 15 }}>
        <Button variant="contained" size="large" type="submit">
          Register
        </Button>
      </div>
    </form>
  )
}

export default RegisterForm
