import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles(() => ({
  pageContainer: {
    height: 'calc(100% - 64px)',
    position: 'relative',
    textAlign: 'center',
    paddingTop: 64,
    color: '#ffffff',
    '@media (max-width: 767px)': {
      marginTop: 55,
    },
  },
  pageTitle: {
    fontWeight: 'bold',
    color: '#ffffff',
    marginTop: 20,
    marginBottom: 20,
  },
  container: {
    position: 'relative',
    margin: '20px auto',
    border: 0,
    outline: 'none',
    boxShadow: '0 4px 0 rgba(0,0,0,.075),inset 0 -4px 0 rgba(0,0,0,.075)',
    background: '#ffffff',
    width: 'auto',
    padding: 20,
    maxWidth: 600,
  },
  userImage: {
    marginBottom: 20,
  },
  userImageAvatar: {
    margin: '0 auto',
    border: '2px solid #eee',
    cursor: 'pointer',
    width: 70,
    height: 70,
    '&:hover': {
      border: '2px dotted #3ecc51',
    },
  },
  dropzoneContent: {
    borderRadius: '50%',
    width: 70,
    height: 70,
    background: 'grey',
    margin: '0 auto',
    color: '#ffffff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '2px solid #eee',
    '&:hover': {
      border: '2px dotted #3ecc51',
    },
  },
  dropzoneIcon: {
    fontSize: 30,
    '&:hover': {
      display: 'none',
    },
  },
  dropZoneIconEdit: {
    fontSize: 30,
    display: 'none',
    '&:hover': {
      display: 'block',
    },
  },
  title: {
    color: '#2e2e2e',
    marginBottom: 15,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    color: '#2e2e2e',
    maxWidth: 400,
    margin: '0 auto 15px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  formInput: {
    fontSize: '1em',
    margin: '0 5px 20px',
    width: 280,
  },
}))
