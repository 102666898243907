import React from 'react'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Tooltip } from '@mui/material'
import { useForm } from 'react-hook-form'

import { Input, Checkbox } from 'components/form'
import { useApp, usePool, useUser } from 'hooks'

import { savePoolSettings } from 'mutations'

import { useStyles } from './SettingsSectionForm.styles'
import { AiFillQuestionCircle } from 'react-icons/ai'

const schema = Yup.object({
  exactScoreline: Yup.number()
    .positive('Must be a positive number')
    .required('required'),
  goalDifference: Yup.number()
    .positive('Must be a positive number')
    .required('required'),
  outcome: Yup.number()
    .positive('Must be a positive number')
    .required('required'),
  champion: Yup.number()
    .positive('Must be a positive number')
    .required('required'),
  topScorer: Yup.number()
    .positive('Must be a positive number')
    .required('required'),
}).required()

const SettingsSectionForm = () => {
  const { showAlert } = useApp()
  const classes = useStyles()
  const { pool, competition } = usePool()
  const { id } = useUser()

  const { control, handleSubmit } = useForm({
    defaultValues: {
      exactScoreline: pool?.settings?.exactScoreline
        ? pool.settings.exactScoreline
        : competition.settings.exactScoreline,
      goalDifference: pool?.settings?.goalDifference
        ? pool.settings.goalDifference
        : competition.settings.goalDifference,
      outcome: pool?.settings?.outcome
        ? pool.settings.outcome
        : competition.settings.outcome,
      champion: pool?.settings?.champion
        ? pool.settings.champion
        : competition.settings.champion,
      topScorer: pool?.settings?.topScorer
        ? pool.settings.topScorer
        : competition.settings.topScorer,
      doubleScoreInPlayoffs:
        pool?.settings?.doubleScoreInPlayoffs !== undefined
          ? pool.settings.doubleScoreInPlayoffs
          : competition.settings.doubleScoreInPlayoffs,
    },
    resolver: yupResolver(schema),
  })

  const onSubmit = values => {
    savePoolSettings(pool.id, values).then(() => {
      showAlert({ message: 'Settings have been saved!' })
    })
  }

  const isOwner = pool?.ownerUserId === id

  return (
    <div className={classes.container}>
      <form className={classes.settings} onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.setting}>
          <div className={classes.settingTitle}><Tooltip disableFocusListener enterTouchDelay={0} leaveTouchDelay={5000} title="Predict the correct home team and away steam score"><div className={classes.settingTooltip}><AiFillQuestionCircle/></div></Tooltip> Exact scoreline</div>
          <Input
            type="number"
            name="exactScoreline"
            control={control}
            className={classes.settingInput}
            disabled={!isOwner}
          />
        </div>
        <div className={classes.setting}>
          <div className={classes.settingTitle}><Tooltip disableFocusListener enterTouchDelay={0} leaveTouchDelay={5000} title="Predict the difference of goals between the home team and away team"><div className={classes.settingTooltip}><AiFillQuestionCircle/></div></Tooltip> Goal difference</div>
          <Input
            type="number"
            name="goalDifference"
            control={control}
            className={classes.settingInput}
            disabled={!isOwner}
          />
        </div>
        <div className={classes.setting}>
          <div className={classes.settingTitle}><Tooltip disableFocusListener enterTouchDelay={0} leaveTouchDelay={5000} title="Predict the game winner"><div className={classes.settingTooltip}><AiFillQuestionCircle/></div></Tooltip> Outcome</div>
          <Input
            type="number"
            name="outcome"
            control={control}
            className={classes.settingInput}
            disabled={!isOwner}
          />
        </div>
        <div className={classes.setting}>
          <div className={classes.settingTitle}><Tooltip disableFocusListener enterTouchDelay={0} leaveTouchDelay={5000} title="Predict the competition final winner"><div className={classes.settingTooltip}><AiFillQuestionCircle/></div></Tooltip> Champion</div>
          <Input
            type="number"
            name="champion"
            control={control}
            className={classes.settingInput}
            disabled={!isOwner}
          />
        </div>
        <div className={classes.setting}>
          <div className={classes.settingTitle}><Tooltip disableFocusListener enterTouchDelay={0} leaveTouchDelay={5000} title="Predict the competition top scoring team"><div className={classes.settingTooltip}><AiFillQuestionCircle/></div></Tooltip> Top scorer</div>
          <Input
            type="number"
            name="topScorer"
            control={control}
            className={classes.settingInput}
            disabled={!isOwner}
          />
        </div>
        <div className={classes.setting}>
          <div className={classes.settingTitle}><Tooltip disableFocusListener enterTouchDelay={0} leaveTouchDelay={5000} title="Double the points per succesful prediction after group stage"><div className={classes.settingTooltip}><AiFillQuestionCircle/></div></Tooltip>Double score after group stage</div>
          <Checkbox
            name="doubleScoreInPlayoffs"
            control={control}
            className={classes.settingInput}
            disabled={!isOwner}
          />
        </div>
        {isOwner &&
          <div className={classes.button}>
            <Button variant="contained" size="large" type="submit">
              Save
            </Button>
          </div>
        }
      </form>
    </div>
  )
}

export default SettingsSectionForm
