import React from 'react'
import PropTypes from 'prop-types'
import { TextField } from '@mui/material'
import { Controller } from 'react-hook-form'

const Input = ({ control, name, label, ...rest }) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { value, onChange }, fieldState }) => (
      <TextField
        label={label}
        value={value ?? ''}
        onChange={onChange}
        {...rest}
        error={!!fieldState.error}
        helperText={fieldState.error ? fieldState.error.message : ''}
      />
    )}
  />
)

Input.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
}

Input.defaultProps = {
  label: '',
}

export default Input
