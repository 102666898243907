import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  container: {
    padding: '20px 40px',
    background: '#ECEBF3',
    '&:nth-child(even)': {
      background: '#dfdfdf'
    },
    "@media (max-width: 767px)": {
      padding: '30px 0'
    }
  },
  title: {
    color: '#2e2e2e',
    fontFamily: 'Kanit, sans-serif',
    textTransform: 'uppercase',
    fontWeight: 'bold'
  },
  content: {
    display: 'flex',
    maxWidth: 1400,
    margin: '0 auto',
    "@media (max-width: 767px)": {
      flexDirection: 'column'
    }
  },
  groupTable: {
    padding: 20,
  },
  fixtures: {
    flex: 1,
    padding: 20,
  }
}));