import {
  useCollectionData,
  useDocumentData,
} from 'react-firebase-hooks/firestore'
import { query, where, getDoc } from 'firebase/firestore'

import { collection, doc } from 'firebase-client/client'
import { useUser } from 'hooks'

export const useGetMyPools = () => {
  const { id } = useUser()

  const [data, loading, error] = useCollectionData(
    query(
      collection('pools'),
      where('users', 'array-contains', id),
      where('isDeleted', '==', false),
    ),
    { getOptions: { source: 'server' } },
  )

  return [data, loading, error]
}

export const useGetPool = poolId => {
  const [data, loading, error] = useDocumentData(doc('pools', poolId), {
    snapshotListenOptions: { includeMetadataChanges: true },
  })

  return [data, loading, error]
}

export const getUsers = async poolId => {
  const docRef = doc(`pools/${poolId}`)
  const pool = await getDoc(docRef)
  const data = pool.data()
  if (pool && data.users) {
    const docRefs = data.users.map(id => doc(`users/${id}`))
    const result = []
    docRefs.forEach(async docRef => {
      const doc = await getDoc(docRef)
      if (doc.exists()) {
        result.push({
          id: doc.id,
          userName: doc.data().userName,
          email: doc.data().email,
          photoURL: doc.data().photoURL,
          previousResults: doc.data().previousResults,
        })
      }
    })
    return result
  }
  return null
}
