import { useCollectionData, useDocumentData } from 'react-firebase-hooks/firestore'

import { getDoc, query, where, getDocs } from 'firebase/firestore'
import { collection, doc } from 'firebase-client/client'

export const useGetCompetitions = () => {

  const [data, loading, error] = useCollectionData(
    collection('competitions'),
    { getOptions: { source: 'server' } },
  )

  return [data, loading, error]
}

export const useGetCompetition = (competitionId) => {

  const [data, loading, error] = useDocumentData(
    doc('competitionId', competitionId),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  return [data, loading, error]
}

export const getCompetition = async (competitionId) => {
  const docRef = doc(`competitions/${competitionId}`)
  const competition = await getDoc(docRef)
  return competition.data()
}

export const getCompetitionRef = (competitionId) => doc(`competitions/${competitionId}`)

export const getTeamByRef = async (teamRef) => {
  const team = await getDoc(teamRef)
  return team.data()
}

export const getTeams = async () => {

  const q = query(collection("teams"))

  const querySnapshot = await getDocs(q)
  return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }))
}
