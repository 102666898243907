
import React, { useEffect, useState } from 'react'
import { usePool } from 'hooks'

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'

import { useStyles } from './GroupTable.styles'
import { hasValidResult } from 'utils/fixture'

const GroupTable = ({ groupId }) => {
  const classes = useStyles()
  const { fixtures, teams } = usePool()
  const [standings, setStandings] = useState([])

  useEffect(() => {
    const groupFixtures = fixtures.filter(f => f.groupId === groupId)
    const finishedGroupFixtures = groupFixtures.filter(f => hasValidResult(f))
    const teamsArray = Array.from(new Set(groupFixtures.map(f => f.homeTeam.id)))
    const teamsInGroup = teams.filter(t => teamsArray.includes(t.id))
    const standings = teamsInGroup.map(t => ({ ...t, played: 0, wins: 0, draws: 0, loses: 0, goalsFor: 0, goalsAgainst: 0, points: 0 }))
    finishedGroupFixtures.forEach((f) => {
      const homeTeamIndex = standings.findIndex(s => s.id === f.homeTeam.id)
      const awayTeamIndex = standings.findIndex(s => s.id === f.awayTeam.id)
      standings[homeTeamIndex].played += 1
      standings[awayTeamIndex].played += 1
      standings[homeTeamIndex].goalsFor += f.homeTeamScore
      standings[homeTeamIndex].goalsAgainst += f.awayTeamScore
      standings[awayTeamIndex].goalsFor += f.awayTeamScore
      standings[awayTeamIndex].goalsAgainst += f.homeTeamScore
      if (f.homeTeamScore > f.awayTeamScore) {
        standings[homeTeamIndex].wins += 1
        standings[homeTeamIndex].points += 3
      } else if (f.homeTeamScore === f.awayTeamScore) {
        standings[homeTeamIndex].draws += 1
        standings[awayTeamIndex].draws += 1
        standings[homeTeamIndex].points += 1
        standings[awayTeamIndex].points += 1
      } else {
        standings[awayTeamIndex].wins += 1
        standings[awayTeamIndex].points += 3
      }
    })
    setStandings(standings)
  }, [fixtures])

  return (
    <TableContainer className={classes.container}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell> </TableCell>
            <TableCell>Team</TableCell>
            <TableCell align="right" style={{ fontWeight: 'bold' }}>Pts</TableCell>
            <TableCell align="right">Pl</TableCell>
            <TableCell align="right">W</TableCell>
            <TableCell align="right">D</TableCell>
            <TableCell align="right">L</TableCell>
            <TableCell align="right">F</TableCell>
            <TableCell align="right">A</TableCell>
            <TableCell align="right">GD</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {standings.sort((a, b) => b.points - a.points || (b.goalsFor - b.goalsAgainst) - (a.goalsFor - a.goalsAgainst) || b.goalsFor - a.goalsFor).map((row, i) => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell>
                {i + 1}
              </TableCell>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right" style={{ fontWeight: 'bold' }}>{row.points}</TableCell>
              <TableCell align="right">{row.played}</TableCell>
              <TableCell align="right">{row.wins}</TableCell>
              <TableCell align="right">{row.draws}</TableCell>
              <TableCell align="right">{row.played - row.wins - row.draws}</TableCell>
              <TableCell align="right">{row.goalsFor}</TableCell>
              <TableCell align="right">{row.goalsAgainst}</TableCell>
              <TableCell align="right">{row.goalsFor - row.goalsAgainst}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default GroupTable