
export const SEO_TITLE_MAX_LENGTH = 120;
export const SEO_DESCRIPTION_MAX_LENGTH = 160;

export const getPageTitle = (pathname, pool = null) => {
  let title = 'Wibbet';
  switch (pathname) {
    case '/admin':
      title = 'Wibbet | Admin';
      break;
    case '/login':
      title = 'Wibbet | Login';
      break;
    case '/register':
      title = 'Wibbet | Register';
      break;
    default:
      if (pathname.indexOf('/join/') >= 0 && pool) {
        title = `Wibbet | Join ${pool.ownerUserName ? `${pool.ownerUserName}'s Pool: ` : 'Pool: '}${pool.name}`;
      } else if (pathname.indexOf('/pool/') >= 0 && pool) {
        title = `Wibbet | ${pool.name}`;
      } else if (pathname.indexOf('/pools') >= 0) {
        title = `Wibbet | My Pools`;
      }
  }
  return title;
}

export const getPageDescription = (pathname, pool = null) => {
  const description = 'Start your own prediction pool with friends now!';
  // switch (pathname) {
  //   default:
  //     if (pathname.indexOf('/join/') >= 0) {
  //       description = 'Wibbet | Join Pool';
  //     } else if (pathname.indexOf('/pool/') >= 0 && pool) {
  //       description = `Wibbet | Join Pool`;
  //     }
  // }
  return description;
}

const removeWordsToFit = (text, maxLength) => {
  if (text.length > maxLength) {
    const lastSpace = text.lastIndexOf(' ', maxLength);
    let result = text.substring(0, lastSpace);
    const remainingWords = text.substring(lastSpace + 1).split(' ');
    let remainingLength = maxLength - result.length;
    // add words till we pass the length
    for (let i = 0; remainingLength - remainingWords[i].length > 0 && i < remainingWords.length; i += 1) {
      result += ` ${remainingWords[i]}`;
      remainingLength -= remainingWords[i].length;
    }
    return result;
  }
  return text;
}

const decodeHtmlEntity = (str) => str.replace(/&#(\d+);/g, (match, dec) => String.fromCharCode(dec));

export const updatePageTitle = (pathname, pool) => {
  let title = getPageTitle(pathname, pool);
  if (title && title.length > SEO_TITLE_MAX_LENGTH) {
    title = removeWordsToFit(title, SEO_TITLE_MAX_LENGTH);
  }
  title = decodeHtmlEntity(title);
  document.title = title;
  document.head.querySelector('meta[name="twitter:title"]').content = title;
  document.head.querySelector('meta[property="og:title"]').content = title;
}

export const updatePageDescription = (pathname, pool) => {
  let description = getPageDescription(pathname, pool);
  if (description && description.length > SEO_DESCRIPTION_MAX_LENGTH) {
    description = removeWordsToFit(description, SEO_DESCRIPTION_MAX_LENGTH);
  }
  description = decodeHtmlEntity(description);
  document.head.querySelector('meta[name="description"]').content = description;
  document.head.querySelector('meta[name="twitter:description"]').content = description;
  document.head.querySelector('meta[property="og:description"]').content = description;
}

export const updatePageOgURL = (pathname) => {
  document.head.querySelector('meta[property="og:url"]').content = `https://wibbet.co${pathname}`;
  document.head.querySelector('link[rel="canonical"]').href = `https://wibbet.co${pathname}`;
}