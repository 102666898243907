import React from 'react'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button } from '@mui/material'
import { useForm } from 'react-hook-form'

import { Input, Autocomplete, Select, DateTimePicker } from 'components/form'
import { useApp } from 'hooks'

import { addFixture, updateFixture } from 'mutations'

import { useStyles } from './AdminFixtureForm.styles'

const schema = Yup.object({
  competitionId: Yup.string().required('required'),
  stageId: Yup.string().required('required'),
  groupId: Yup.string().required('required'),
  homeTeam: Yup.object({ id: Yup.string().required() })
    .required('required')
    .default(undefined),
  homeTeamScore: Yup.number()
    .transform(v => (v === '' || Number.isNaN(v) ? null : v))
    .nullable()
    .min(0, 'Must be greater than 0'),
  awayTeam: Yup.object({ id: Yup.string().required() })
    .required('required')
    .default(undefined),
  awayTeamScore: Yup.number()
    .transform(v => (v === '' || Number.isNaN(v) ? null : v))
    .nullable()
    .min(0, 'Must be greater than 0'),
  date: Yup.date().required('required'),
}).required()

const AdminFixtureForm = ({ competition, fixture, teams, onSuccess }) => {
  const { showAlert } = useApp()
  const classes = useStyles()

  const { control, watch, handleSubmit } = useForm({
    defaultValues: {
      stageId: 'group_stage',
      groupId: '',
      homeTeam: null,
      awayTeam: null,
      homeTeamScore: null,
      awayTeamScore: null,
      competitionId: competition.id,
      ...fixture,
      date: fixture?.date?.toDate() ?? new Date('2024-07-09T22:00:00'),
    },
    resolver: yupResolver(schema),
  })

  const onSubmit = values => {
    if (fixture) {
      updateFixture({ fixtureId: fixture.id, values }).then(fixture => {
        showAlert({ message: 'Fixture was updated' })
        onSuccess(fixture)
      })
    } else {
      addFixture(values).then(fixture => {
        showAlert({ message: 'Fixture was added' })
        onSuccess(fixture)
      })
    }
  }

  const watchStageId = watch('stageId')

  return (
    <div className={classes.container}>
      <form className={classes.settings} onSubmit={handleSubmit(onSubmit)}>
        <br />
        {/* <Input
          name="index"
          label="Fixture Index"
          control={control}
        />
        <Input
          name="name"
          label="Fixture Name"
          control={control}
        /> */}
        <Autocomplete
          control={control}
          name="homeTeam"
          label="Home Team"
          disablePortal
          options={teams}
          getOptionLabel={option => option.name}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          autoHighlight
          disableClearable
          sx={{ width: 200 }}
        />
        <br />
        <Input
          name="homeTeamScore"
          label="Home Team Score"
          control={control}
          type="tel"
          sx={{ width: 200 }}
        />
        <br />
        <br />
        <Autocomplete
          control={control}
          name="awayTeam"
          label="Away Team"
          disablePortal
          options={teams}
          getOptionLabel={option => option.name}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          autoHighlight
          disableClearable
          sx={{ width: 200 }}
        />
        <br />
        <Input
          name="awayTeamScore"
          label="Away Team Score"
          control={control}
          type="tel"
          sx={{ width: 200 }}
        />
        <br />
        <br />
        <Select
          control={control}
          name="stageId"
          label="Stage"
          options={competition.stages.map(s => ({
            value: s.id,
            label: s.name,
          }))}
          sx={{ width: 200 }}
        />
        <br />
        <br />
        {watchStageId && (
          <Select
            control={control}
            name="groupId"
            label="Group"
            options={competition.stages
              .find(s => s.id === watchStageId)
              ?.groups.map(g => ({ value: g.id, label: g.name }))}
            sx={{ width: 200 }}
          />
        )}
        <br />
        <br />
        <DateTimePicker
          control={control}
          name="date"
          label="Fixture Date"
          closeOnSelect
          showToolbar={false}
          variant="static"
        />
        <br />
        <br />
        <div className={classes.button}>
          <Button variant="contained" size="large" type="submit">
            Save
          </Button>
        </div>
      </form>
    </div>
  )
}

export default AdminFixtureForm
