import { Button, DialogContent, DialogTitle, Typography, useMediaQuery } from '@mui/material'
import React, { useState } from 'react'
import Fixture from './Fixture'
import { usePool } from 'hooks'
import GroupTable from './GroupTable'
import { Dialog } from 'components/ui'

import { useStyles } from './GroupSection.styles'

const GroupSection = ({ stageId, group }) => {
  const classes = useStyles()
  const { pool, fixtures, settings } = usePool()
  const isMobile = useMediaQuery('(max-width:767px)')
  const isGroupStage = stageId === 'group_stage'
  const [groupTableDialogOpen, setGroupTableDialogOpen] = useState(false)

  return (
    <div className={classes.container}>
      <Typography variant="h4" className={classes.title}>
        {group.name}
      </Typography>
      <div className={classes.content}>
        {!isMobile && isGroupStage &&
          <div className={classes.groupTable}>
            <GroupTable groupId={group.id}/>
          </div>
        }
        <div className={classes.fixtures}>
          {fixtures.filter(f => f.stageId === stageId && f.groupId === group.id).sort((a, b) => a.date.seconds - b.date.seconds).map(fixture => (
            <div key={`fixture-${fixture.id}`}>
              <Fixture fixture={fixture} settings={settings} pool={pool}/>
            </div>
          ))}
        </div>
        {isMobile && isGroupStage &&
          <div>
            <Button variant="contained" onClick={() => setGroupTableDialogOpen(true)}>Group Table</Button>
          </div>
        }
      </div>
      {isGroupStage &&
        <Dialog
          open={groupTableDialogOpen}
          onClose={() => setGroupTableDialogOpen(false)}
          aria-labelledby="group-table-dialog-title"
          maxWidth="sm"
          title={group.name}
          setDialogOpen={setGroupTableDialogOpen}
          fullWidth
        >
          <div className={classes.dialogGroupTable}>
            <GroupTable groupId={group.id}/>
          </div>
        </Dialog>
      }
    </div>
  )
}

export default GroupSection
