import React from 'react';
import PropTypes from 'prop-types';

import { Snackbar, Alert as MaterialAlert, AlertTitle as MaterialAlertTitle, IconButton } from '@mui/material';

import { FaTimes } from 'react-icons/fa';

const Alert = ({
  title,
  message,
  type,
  variant,
  icon,
  isClosable,
  onClose,
  action
}) => (
    <Snackbar open autoHideDuration={3000} onClose={onClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
      <MaterialAlert
        variant={variant}
        severity={type}
        icon={icon}
        action={
          <>
            {action}
            {isClosable &&
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={onClose}
              >
                <FaTimes fontSize="inherit" />
              </IconButton>
            }
          </>
        }
      >
        <div className="alert-contents-wrapper">
          {title &&
            <MaterialAlertTitle style={{ margin: 0 }}><span dangerouslySetInnerHTML={{ __html: title }} /></MaterialAlertTitle>
          }
          <span dangerouslySetInnerHTML={{ __html: message || '&nbsp;' }} />
        </div>
      </MaterialAlert>
    </Snackbar>
  )

Alert.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  type: PropTypes.string,
  variant: PropTypes.string,
  icon: PropTypes.node,
  isClosable: PropTypes.bool,
  onClose: PropTypes.func,
  action: PropTypes.node
};

Alert.defaultProps = {
  title: '',
  message: '',
  type: 'success',
  variant: 'filled',
  icon: undefined,
  isClosable: true,
  onClose: undefined,
  action: undefined
};

export default Alert;
