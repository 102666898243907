import React from 'react'

import { Dialog } from 'components/ui'
import { RegisterForm } from 'pages/register/components'
import { useNavigate } from 'react-router-dom'
import { Divider } from '@mui/material'
import { continueWithGoogle } from 'mutations'

const googleLoginButton = (
  <svg
    version="1.1"
    x="0px"
    y="0px"
    viewBox="198 730 1403 222"
    style={{ filter: 'drop-shadow(1px 1px 2px rgb(0 0 0 / 0.4))' }}
  >
    <g>
      <g>
        <rect
          x="202"
          y="733"
          width="1400"
          height="214"
          rx="20"
          ry="20"
          fill="#4285F4"
        />
        <g>
          <text
            transform="matrix(1 0 0 1 645.688 861.3538)"
            style={{
              fill: '#ffffff',
              fontFamily: 'Roboto',
              fontWeight: 500,
              fontSize: '72px',
              letterSpacing: 2,
            }}
          >
            {' '}
            Continue with Google
          </text>
        </g>
        <g>
          <rect
            x="210"
            y="740"
            width="210"
            height="200"
            rx="14"
            ry="14"
            fill="#ffffff"
          />
          <path
            style={{ fill: '#4877BB' }}
            d="M363.5,841.4c0-4.5-0.4-7.8-1.2-11.3h-51.8v20.5h30.4c-0.6,5.1-3.9,12.7-11.3,17.9l-0.1,0.7l16.4,12.7
          l1.1,0.1C357.5,872.4,363.5,858.2,363.5,841.4"
          />
          <path
            style={{ fill: '#21A849' }}
            d="M310.5,895.4c14.9,0,27.4-4.9,36.5-13.4l-17.4-13.5c-4.7,3.2-10.9,5.5-19.1,5.5c-14.6,0-27-9.6-31.4-22.9
          l-0.6,0.1l-17,13.2l-0.2,0.6C270.3,883,289,895.4,310.5,895.4"
          />
          <path
            style={{ fill: '#FBBA10' }}
            d="M279.1,851.1c-1.2-3.4-1.8-7.1-1.8-10.9c0-3.8,0.7-7.5,1.8-10.9l0-0.7l-17.2-13.4l-0.6,0.3
          c-3.7,7.5-5.9,15.9-5.9,24.8c0,8.9,2.1,17.3,5.9,24.8L279.1,851.1"
          />
          <path
            style={{ fill: '#E73830' }}
            d="M310.5,806.4c10.4,0,17.3,4.5,21.3,8.2l15.6-15.2c-9.6-8.9-22-14.3-36.9-14.3c-21.6,0-40.2,12.4-49.3,30.4
          l17.8,13.9C283.6,816,295.9,806.4,310.5,806.4"
          />
        </g>
      </g>
    </g>
  </svg>
)

const RegisterDialog = ({ onLoginClick, onClose, ...props }) => {
  const navigate = useNavigate()
  return (
    <Dialog
      {...props}
      title="Register"
      maxWidth="xs"
      fullWidth
      onClose={() => (onClose ? onClose() : navigate('/'))}
    >
      <div
        style={{ maxWidth: 300, margin: '0 auto 30px', cursor: 'pointer' }}
        onClick={continueWithGoogle}
      >
        {googleLoginButton}
      </div>
      <Divider style={{ marginBottom: 30 }} />
      <RegisterForm />
      <p style={{ textAlign: 'center' }}>
        Already a member?{' '}
        <span
          onClick={onLoginClick}
          style={{
            cursor: 'pointer',
            color: '#10303E',
            fontWeight: 'bold',
            textDecoration: 'underline',
          }}
        >
          LOGIN
        </span>
      </p>
    </Dialog>
  )
}

export default RegisterDialog
