import { addDoc, updateDoc, getDoc } from 'firebase/firestore'
import { collection, doc } from 'firebase-client/client'

export const addPrediction = async (values) => {
  const docRef = await addDoc(collection('predictions'), values)
  return { ...values, id: docRef.id }
}

export const updatePrediction = async ({ predictionId, values }) => {
  const predictionRef = doc(`predictions/${predictionId}`)
  await updateDoc(predictionRef, values)
  const prediction = await getDoc(predictionRef)
  return { id: prediction.id, ...prediction.data() }
}
