export const hasValidResult = (o) => !!o && 'homeTeamScore' in o && 'awayTeamScore' in o && o.homeTeamScore !== null && o.awayTeamScore !== null && o.homeTeamScore !== undefined && o.awayTeamScore !== undefined

export const getPredictionPoints = (prediction, fixture, settings) => {
  const { stageId, status, homeTeamScore, awayTeamScore } = fixture
  const points = { exactScoreline: 0, goalDifference: 0, outcome: 0, total: 0, isMax: false }
  if (status === 'finished' && hasValidResult(prediction)) {
    const multiplier = settings.doubleScoreInPlayoffs && stageId !== 'group_stage' ? 2 : 1
    if (homeTeamScore === prediction.homeTeamScore && awayTeamScore === prediction.awayTeamScore) {
      points.exactScoreline += settings.exactScoreline * multiplier
      points.isMax = true
    } else if (homeTeamScore - prediction.homeTeamScore === awayTeamScore - prediction.awayTeamScore) {
      points.goalDifference += settings.goalDifference * multiplier
    } else if ((homeTeamScore - awayTeamScore > 0 && prediction.homeTeamScore - prediction.awayTeamScore > 0) || (homeTeamScore - awayTeamScore < 0 && prediction.homeTeamScore - prediction.awayTeamScore < 0)) {
      points.outcome += settings.outcome * multiplier
    }
    points.total = points.exactScoreline + points.goalDifference + points.outcome
  }
  return points
}