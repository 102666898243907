import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  GoogleAuthProvider,
  signInWithPopup,
  updateProfile,
} from 'firebase/auth'

import { firebaseAuth, doc } from 'firebase-client/client'
import { setDoc, getDoc } from 'firebase/firestore'
import { getStorage, ref, uploadString, getDownloadURL } from 'firebase/storage'

const provider = new GoogleAuthProvider()

export const loginWithEmailAndPassword = async (email, password) => {
  try {
    return await signInWithEmailAndPassword(firebaseAuth, email, password)
  } catch (error) {
    console.log(error)
    return { error }
  }
}

export const registerWithEmailAndPassword = async (
  userName,
  email,
  password,
) => {
  try {
    const { user } = await createUserWithEmailAndPassword(
      firebaseAuth,
      email,
      password,
    )
    await updateProfile(user, { displayName: userName })
    return user
  } catch (error) {
    console.log(error)
    return { error }
  }
}

export const continueWithGoogle = async () => {
  try {
    const { user } = await signInWithPopup(firebaseAuth, provider)
    return user
  } catch (error) {
    console.log(error)
    return { error }
  }
}

export const updateUser = async (id, payload) => {
  try {
    const { photoURL, photoFileName, photo, ...rest } = payload
    let userPhotoURL = photoURL
    if (photo && photoFileName) {
      const storage = getStorage()
      const storageRef = ref(
        storage,
        `/profile-pictures/${id}/${photoFileName}`,
      )
      const fileSnapshot = await uploadString(storageRef, photo, 'data_url')
      userPhotoURL = await getDownloadURL(fileSnapshot.ref)
    }
    const userRef = doc('users', id)
    await setDoc(userRef, { ...rest, photoURL: userPhotoURL }, { merge: true })
    const userDoc = await getDoc(userRef)
    return { id, ...userDoc.data() }
  } catch (error) {
    console.log(error)
    return { error }
  }
}

export const logout = async () => {
  try {
    return signOut(firebaseAuth)
  } catch (error) {
    console.log(error)
    return { error }
  }
}
