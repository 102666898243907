import { TextField } from '@mui/material'
import { useUser, usePool, useApp } from 'hooks'
import React, { useRef, useState } from 'react'
import { useStyles } from './FixtureForm.styles'

const FixtureForm = ({ fixture }) => {

  const homeTeamScoreInput = useRef()
  const awayTeamScoreInput = useRef()
  const { showAlert } = useApp()
  const { pool, competition, addPrediction, updatePrediction } = usePool()
  const [submitting, setSubmitting] = useState(false)
  const [prediction, setPrediction] = useState(fixture.prediction)
  const [homeTeamScore, setHomeTeamScore] = useState(!Number.isNaN(parseInt(prediction?.homeTeamScore, 10)) ? prediction.homeTeamScore : '')
  const [awayTeamScore, setAwayTeamScore] = useState(!Number.isNaN(parseInt(prediction?.awayTeamScore, 10)) ? prediction.awayTeamScore : '')
  const { id } = useUser()

  const classes = useStyles({ homeTeamScore: prediction?.homeTeamScore ?? '', awayTeamScore: prediction?.awayTeamScore ?? '' })

  const onSuccess = (prediction) => {
    setPrediction(prediction)
    setSubmitting(false)
  }

  const onPredictionBlur = (e) => {
    if (fixture.date?.toDate() > new Date()) {
      const isSameForm = e.relatedTarget === homeTeamScoreInput.current || e.relatedTarget === awayTeamScoreInput.current
      const parsedIntHomeTeamScore = parseInt(homeTeamScore, 10)
      const parsedIntAwayTeamScore = parseInt(awayTeamScore, 10)
      if (!isSameForm && !Number.isNaN(parsedIntHomeTeamScore) && !Number.isNaN(parsedIntAwayTeamScore)) {
        setSubmitting(true)
        if (prediction) {
          updatePrediction({ predictionId: prediction.id, values: { homeTeamScore: parsedIntHomeTeamScore, awayTeamScore: parsedIntAwayTeamScore } }).then(onSuccess)
        } else {
          addPrediction({ poolId: pool.id, fixtureId: fixture.id, competitionId: competition.id, homeTeamScore: parsedIntHomeTeamScore, awayTeamScore: parsedIntAwayTeamScore, userId: id }).then(onSuccess)
        }
      }
    } else {
      showAlert({ message: 'Fixture has already started.', type: 'error' })
    }
  }

  return (
    <div className={classes.container}>
      <TextField
        value={homeTeamScore}
        id="homeTeamScore"
        fullWidth
        className={classes.homeTeamInput}
        inputRef={homeTeamScoreInput}
        onChange={(e) => setHomeTeamScore(!Number.isNaN(parseInt(e.target.value, 10)) ? parseInt(e.target.value, 10) : '')}
        onBlur={onPredictionBlur}
        disabled={submitting}
        type="tel"
      />
      <TextField
        value={awayTeamScore}
        id="awayTeamScore"
        fullWidth
        className={classes.awayTeamInput}
        inputRef={awayTeamScoreInput}
        onChange={(e) => setAwayTeamScore(!Number.isNaN(parseInt(e.target.value, 10)) ? parseInt(e.target.value, 10) : '')}
        onBlur={onPredictionBlur}
        disabled={submitting}
        type="tel"
      />
    </div>
  )
}

export default FixtureForm