import { addDoc, arrayUnion, arrayRemove, updateDoc } from 'firebase/firestore'
import { collection, doc } from 'firebase-client/client'

export const addPool = async data => {
  const docRef = await addDoc(collection('pools'), {
    ...data,
    isDeleted: false,
  })

  return { data, id: docRef.id }
}

export const deletePool = async poolId => {
  const poolRef = doc(`pools/${poolId}`)

  return updateDoc(poolRef, {
    isDeleted: true,
    deleted: new Date(),
  })
}

export const addUserToPool = async (userId, poolId) => {
  const poolRef = doc(`pools/${poolId}`)
  const userRef = doc(`users/${userId}`)
  await updateDoc(poolRef, {
    users: arrayUnion(userId),
  })
  await updateDoc(userRef, {
    pools: arrayUnion(poolId),
  })
}

export const approveUserInPool = async (userId, poolId) => {
  const poolRef = doc(`pools/${poolId}`)
  await updateDoc(poolRef, {
    approvedUsers: arrayUnion(userId),
  })
}

export const unapproveUserInPool = async (userId, poolId) => {
  const poolRef = doc(`pools/${poolId}`)
  await updateDoc(poolRef, {
    approvedUsers: arrayRemove(userId),
  })
}

export const savePoolSettings = async (poolId, settings) => {
  const poolRef = doc(`pools/${poolId}`)
  await updateDoc(poolRef, { settings })
}
