import { Timestamp } from 'firebase/firestore'

export const timestampToDate = timestamp => {
  const date = new Timestamp(timestamp.seconds, timestamp.nanoseconds).toDate()
  return  date.toLocaleString([], { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' })
}

export const shortTimestampToDate = timestamp => {
  const date = new Timestamp(timestamp.seconds, timestamp.nanoseconds).toDate()
  return  date.toLocaleString([], { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' })
}