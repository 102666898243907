import React from 'react'
import PropTypes from 'prop-types'
import {
  FormControl,
  InputLabel,
  Select as MuiSelect,
  MenuItem,
  FormHelperText,
} from '@mui/material'
import { Controller } from 'react-hook-form'

const Select = ({ control, name, options, label, fullWidth, ...rest }) => (
  <Controller
    name={name}
    control={control}
    render={({ field, fieldState }) => (
      <FormControl fullWidth error={!!fieldState.error}>
        <InputLabel>{label}</InputLabel>
        <MuiSelect label={label} {...field} {...rest}>
          {options?.map(({ value, label }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </MuiSelect>
        {fieldState.error && (
          <FormHelperText>{fieldState.error.message}</FormHelperText>
        )}
      </FormControl>
    )}
  />
)

Select.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  fullWidth: PropTypes.bool,
}

Select.defaultProps = {
  label: '',
  fullWidth: true,
}

export default Select
