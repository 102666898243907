import React from 'react'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'

const style = { color: 'red' }

const GeneralError = ({ control }) => (
  <Controller
    name="generalError"
    control={control}
    render={({ fieldState, formState }) =>
      !formState.isValid && fieldState.error ? (
        <p style={style}>{fieldState.error.message}</p>
      ) : null
    }
  />
)

GeneralError.propTypes = {
  control: PropTypes.object.isRequired,
}

export default GeneralError
