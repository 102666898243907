import React, { useState } from 'react'
import { Dialog, DialogContent, DialogTitle } from '@mui/material'
import { IoClose } from 'react-icons/io5'

import { AddPoolForm } from 'pages/pools/components'
import { useApp, useUser } from 'hooks'
import { useGetCompetitions } from 'queries'
import { addPool } from 'mutations'

export const addPoolGeneralErrorCodes = []
export const addPoolNameErrorCodes = []
export const addPoolCompetitionErrorCodes = []

const AddPoolDialog = ({
  open,
  setDialogOpen,
  closable = true,
  onSuccess = undefined,
}) => {
  const [error, setError] = useState()
  const [competitions, loading] = useGetCompetitions()
  const { id, userName } = useUser()

  const { showAlert } = useApp()

  const onSubmit = ({ name, competitionId }) => {
    const now = new Date()

    addPool({
      ownerUserId: id,
      ownerUserName: userName,
      name,
      competitionId,
      users: [id],
      created: now,
      updated: now,
    })
      .then(result => {
        showAlert({ message: 'Added pool ' })
        if (onSuccess) onSuccess(result)
      })
      .catch(setError)
  }

  if (loading) {
    return null
  }

  return (
    <Dialog
      open={open}
      onClose={() => closable && setDialogOpen && setDialogOpen(false)}
      aria-labelledby="add-dialog-title"
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle id="add-dialog-title">
        Create Pool
        {closable && setDialogOpen && (
          <div
            aria-label="close"
            onClick={() => setDialogOpen(false)}
            style={{
              position: 'absolute',
              right: 10,
              top: 10,
              cursor: 'pointer',
            }}
          >
            <IoClose />
          </div>
        )}
      </DialogTitle>
      <DialogContent>
        <br/>
        <AddPoolForm
          onSubmit={onSubmit}
          competitions={competitions.filter(c => c.live)}
          submitError={error}
        />
      </DialogContent>
    </Dialog>
  )
}

export default AddPoolDialog
