import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    margin: '20px auto',
    border: 0,
    outline: 'none',
    boxShadow: '0 4px 0 rgba(0,0,0,.075),inset 0 -4px 0 rgba(0,0,0,.075)',
    background: '#ffffff',
    width: 'auto',
    padding: 20,
    maxWidth: 600
  },
  title: {
    color: '#2e2e2e',
    marginBottom: 15,
  },
  setting: {
    display: 'flex',
    color: '#2e2e2e',
    maxWidth: 400,
    margin: '0 auto 15px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  settingTitle: {
    marginRight: 'auto',
  },
  settingTooltip: {
    display: 'inline-block',
    color: '#3ecc51',
    verticalAlign: 'middle',
    marginRight: 5,
    "@media (max-width: 767px)": {
      fontSize: 24
    }
  },
  settingInput: {
    fontSize: '1em',
    width: '3em',
    margin: '0 5px',
    '& .MuiInputBase-input': {
      padding: '14px 0',
      textAlign: 'center',
    },
  },
}))
