import React, { useState } from 'react'
import { Button } from '@mui/material'

import {
  EmailShareButton,
  FacebookShareButton,
  FacebookMessengerShareButton,
  WhatsappShareButton,
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  WhatsappIcon
} from 'react-share'

import { Dialog } from 'components/ui'
import copy from 'copy-to-clipboard'
import { HiOutlineLink } from 'react-icons/hi'

import { useStyles } from './Share.styles'

const Share = ({
  onClick,
  onSuccess,
  children,
  title,
  text,
  url,
  imageUrl,
  dialogTitle,
  ...props
}) => {

  const [sharePopupOpen, setSharePopupOpen] = useState(false)
  const classes = useStyles()

  const onCloseSharePopup = () => {
    setSharePopupOpen(false)
  }

  const shareNative = async () => {
    await navigator.share({
      text,
      title,
      url,
    })
    onSuccess()
    setSharePopupOpen(false)
  }

  const shareFallback = () => {
    setSharePopupOpen(true)
  }

  const openShare = () => {
    if (typeof navigator !== 'undefined' && navigator.share) {
      shareNative()
    } else {
      shareFallback()
    }
  }

  const onShareWindowClose = () => {
    onSuccess()
    setSharePopupOpen(false)
  }


  const onCopy = () => {
    if (url) {
      copy(`${url}`)
      onSuccess()
      setSharePopupOpen(false)
    }
  }

  return (
    <div className={classes.shareContainer}>
      <span onClick={openShare} className="share-button-content">{children}</span>
      <Dialog
        open={sharePopupOpen}
        title={dialogTitle}
        subtitle="Copy the link or share directly on your favorite social media platform."
        onClose={onCloseSharePopup}
      >
        <div className={classes.shareDialogContent}>
          <Button onClick={onCopy} className={classes.shareLinkButton} variant="contained">
            <HiOutlineLink/>&nbsp;&nbsp;&nbsp;<span>Copy Share Link</span>
          </Button>
          <br/>
          <br/>
          <div className={classes.shareItems}>
            <EmailShareButton className={classes.shareItem} url={url} subject={title} body={text} onShareWindowClose={onShareWindowClose} {...props}>
              {/*
              subject (string): Title of the shared page
              body (string): Email, will be prepended to the url.
              separator (string, default=" "): Separates body from the url
              */}
              <EmailIcon size={40} round />
            </EmailShareButton>
            <FacebookShareButton className={classes.shareItem} url={url} onShareWindowClose={onShareWindowClose} {...props}>
              {/*
              quote (string): A quote to be shared along with the link.
              hashtag (string): A hashtag specified by the developer to be added to the shared content. People will still have the opportunity to remove this hashtag in the dialog. The hashtag should include the hash symbol.
              */}
              <FacebookIcon size={40} round/>
            </FacebookShareButton>
            <FacebookMessengerShareButton className={classes.shareItem} url={url} onShareWindowClose={onShareWindowClose} appId="TODO" redirectUri="https://facebook.com" {...props}>
              {/*
              redirectUri (string): The URL to redirect to after sharing (default: the shared url).
              to (string): A user ID of a recipient. Once the dialog comes up, the sender can specify additional people as recipients.
              */}
              <FacebookMessengerIcon size={40} round/>
            </FacebookMessengerShareButton>
            <WhatsappShareButton className={classes.shareItem} url={url} onShareWindowClose={onShareWindowClose} title={title} {...props}>
              {/*
              title (string): Title of the shared page
              separator (string, default=" "): Separates title from the url
              */}
              <WhatsappIcon size={40} round/>
            </WhatsappShareButton>
          </div>
        </div>
      </Dialog>
    </div>
  )
}

export default Share;
