import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Button, Container, LinearProgress, Typography, List, ListItem, ListItemText, ListItemAvatar, Divider, Avatar, ListItemSecondaryAction } from '@mui/material'
import { GiQueenCrown } from 'react-icons/gi'
import { AiOutlineDelete, AiOutlineTrophy, AiOutlineUsergroupAdd } from 'react-icons/ai'
import { shortTimestampToDate } from 'firebase-client/utils'

import { AddPoolDialog, DeletePoolDialog } from 'pages/pools/components'
import { Share } from 'components/ui'
import { useApp, useUser } from 'hooks'
import { useGetMyPools } from 'queries'

import { useStyles } from './PoolsPage.styles'

const PoolsPage = () => {
  const [isAddPoolDialogOpen, setIsAddPoolDialogOpen] = useState(false)
  const [deleteDialogProps, setDeleteDialogProps] = useState({ isOpen: false })

  const [pools, isLoadingPools] = useGetMyPools()
  const classes = useStyles()
  const navigate = useNavigate()
  const { showAlert } = useApp()
  const { id } = useUser()

  const onAddPoolSuccess = pool => {
    navigate(`/pool/${pool.id}`)
  }

  const onShareSuccess = () => {
    showAlert({ message: `Your link was copied to clipboard. Get sending!` })
  }

  const ownedPools = pools ? pools.filter(p => p.ownerUserId === id) : []
  const joinedPools = pools ? pools.filter(p => p.ownerUserId !== id) : []

  if (isLoadingPools) {
    return <LinearProgress />
  }

  return (
    <div className={classes.pageContainer}>
      <Typography component="h1" variant="h4" className={classes.pageTitle}>
        My Pools
      </Typography>
      <Container>
        {pools && pools.length > 0 &&
          <>
            {ownedPools.length > 0 &&
              <div>
                <List className={classes.poolsList}>
                  {ownedPools.map((pool, index) => (
                    <React.Fragment key={`pool-item-${pool.id}`}>
                      <ListItem
                        button
                        onClick={() => navigate(`/pool/${pool.id}`)}
                        className={classes.poolsListItem}
                      >
                        <ListItemAvatar>
                          <Avatar className={classes.poolItemAvatarOwned}>
                            <GiQueenCrown />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={pool.name}
                          secondary={`Created by you on ${shortTimestampToDate(pool.created)}`}
                        />
                        {pool.ownerUserId === id &&
                          <ListItemSecondaryAction style={{ display: 'flex' }}>
                            <div
                              style={{
                                margin: '0 10px',
                                fontSize: 22,
                                cursor: 'pointer',
                              }}
                            >
                              <Share
                                dialogTitle="Invite to Pool"
                                url={`${window.location.origin}/join/${pool.id}`}
                                onSuccess={onShareSuccess}
                              >
                                <AiOutlineUsergroupAdd />
                              </Share>
                            </div>
                            <div
                              style={{
                                margin: '0 10px',
                                fontSize: 22,
                                cursor: 'pointer',
                              }}
                            >
                              <AiOutlineDelete
                                onClick={() =>
                                  setDeleteDialogProps({
                                    isOpen: true,
                                    poolId: pool.id,
                                    poolName: pool.name,
                                  })
                                }
                              />
                            </div>
                          </ListItemSecondaryAction>
                        }
                      </ListItem>
                      {index < ownedPools.length - 1 && <Divider />}
                    </React.Fragment>
                  ))}
                </List>
              </div>
            }
            <br />
            {joinedPools.length > 0 &&
              <div>
                <List className={classes.poolsList}>
                  {joinedPools.map((pool, index) => (
                    <React.Fragment key={`pool-item-${pool.id}`}>
                      <ListItem
                        button
                        onClick={() => navigate(`/pool/${pool.id}`)}
                      >
                        <ListItemAvatar>
                          <Avatar className={classes.poolItemAvatar}>
                            <AiOutlineTrophy />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={pool.name}
                          secondary={`Created by ${pool.ownerUserName || 'Anonymous'} on ${shortTimestampToDate(pool.created)}`}
                        />
                      </ListItem>
                      {index < joinedPools.length - 1 && <Divider />}
                    </React.Fragment>
                  ))}
                </List>
                <br/>
              </div>
            }
          </>
        }
        <Button
          onClick={() => setIsAddPoolDialogOpen(true)}
          variant="contained"
          size="large"
          style={{ color: '#ffffff' }}
        >
          Create Pool
        </Button>
      </Container>
      <AddPoolDialog
        open={isAddPoolDialogOpen}
        setDialogOpen={setIsAddPoolDialogOpen}
        onSuccess={onAddPoolSuccess}
      />

      <DeletePoolDialog
        {...deleteDialogProps}
        setDeleteDialogProps={setDeleteDialogProps}
      />
    </div>
  )
}

export default PoolsPage
