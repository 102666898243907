import React, { useState } from 'react'
import { useApp, usePool, useUser } from 'hooks'

import { FormControl, InputLabel, MenuItem, Select, Tab, Tabs, Box, Button, Typography, LinearProgress } from '@mui/material'
import { timestampToDate } from 'firebase-client/utils'

import StageSection from './StageSection'

import { useStyles } from './PredictionsSection.styles'
import Fixture from './Fixture'
import { hasValidResult } from 'utils/fixture'


const a11yProps = (index, tab = true) => {
  if (tab) {
    return {
      id: `pool-tab-${index}`,
      'aria-controls': `pool-tabpanel-${index}`,
    }
  }
  return {
    id: `pool-tabpanel-${index}`,
    'aria-labelledby': `pool-tab-${index}`,
  }
}
const TabPanel = ({ children, value, index, ...other }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    {...a11yProps(index, false)}
    {...other}
  >
    {value === index && <Box p={0}>{children}</Box>}
  </div>
)

const getStartingTab = (competition, topScorer, champion) => {
  if (competition.date?.toDate() > new Date() && !(topScorer && champion)) {
    return 0
  }
  return 1
}

const PredictionsSection = () => {
  const classes = useStyles()
  const { showAlert } = useApp()
  const { pool, competition, poolPredictions, addPrediction, updatePrediction, teams, fixtures, settings, loaded } = usePool()
  const { id } = useUser()
  const { topScorer, champion } = poolPredictions
  const [tab, setTab] = useState(getStartingTab(competition, topScorer, champion))
  const [viewFinishedFixtures, setViewFinishedFixtures] = useState(false)

  const handleTabChange = (event, newValue) => {
    setTab(newValue)
  }

  const onPoolPredictionAdd = (e) => {
    if (e.target && competition.date?.toDate() > new Date()) {
      const poolPrediction = e.target.name === 'topScorer' ? topScorer : champion
      if (!poolPrediction) {
        addPrediction({ poolId: pool.id, competitionId: competition.id, userId: id, teamId: e.target.value, name: e.target.name })
      } else {
        updatePrediction({ predictionId: poolPrediction.id, userId: id, values: { ...poolPrediction, teamId: e.target.value }})
      }
    } else {
      showAlert({ message: 'Competition has started.', type: 'error' })
    }
  }

  const topScorerTeam = topScorer && topScorer.teamId ? teams.find(t => t.id === topScorer.teamId) : undefined
  const championTeam = champion && champion.teamId ? teams.find(t => t.id === champion.teamId) : undefined

  if (!loaded) {
    return (
      <div style={{ width: '100%' }}>
        <LinearProgress />
      </div>
    )
  }

  return (
    <div className={classes.container}>
      <Tabs
        className={classes.stageTabs}
        value={tab}
        onChange={handleTabChange}
        aria-label="Pool Stages"
        variant="scrollable"
      >
        <Tab
          style={{ fontSize: 14, fontWeight: 700, minHeight: 50 }}
          label="Pool Predictions"
          iconPosition="start"
          {...a11yProps(0)}
        />
        <Tab
          style={{ fontSize: 14, fontWeight: 700, minHeight: 50 }}
          label="All Games"
          iconPosition="start"
          {...a11yProps(1)}
        />
        {competition.stages.map((s, i) => (
          <Tab
            key={`stage-tab-${s.id}`}
            style={{ fontSize: 14, fontWeight: 700, minHeight: 50 }}
            label={s.name}
            iconPosition="start"
            {...a11yProps(i + 2)}
          />
        ))}
      </Tabs>
      <div className={classes.content}>
        <TabPanel value={tab} index={0}>
          <div className={classes.tabContainer}>
            <Typography component="h1" variant="h4" className={classes.tabTitle}>
              {competition.date?.toDate() > new Date() ? `Let's Get Started` : 'Pool Predictions'}
            </Typography>
            <Typography variant="p" className={classes.tabSubtitle}>
              {competition.date?.toDate() > new Date() ? `Set your pool predictions before the competition start on ${timestampToDate(competition.date)}` : 'Your pool predictions are now locked in. Good luck!'}
            </Typography>
            <div className={classes.poolPredictions}>
              <div className={classes.poolPrediction}>
                <span>Top Scoring Team:</span>
                {competition.date?.toDate() < new Date() && topScorerTeam ?
                  <div className={classes.poolPredictionTeam}>
                    <img className={classes.poolPredictionLogo} src={`${process.env.PUBLIC_URL}/teams/logos/${topScorerTeam.logo}`} alt={topScorerTeam.name}/>
                    <span>{topScorerTeam.name}</span>
                  </div>
                :
                  <FormControl sx={{ minWidth: 150 }} className={classes.poolPredictionSelect} disabled={competition.date?.toDate() < new Date()}>
                    <InputLabel id="topScorerSelectLabel">Top Scoring</InputLabel>
                    <Select
                      id="topScorer"
                      name="topScorer"
                      label="Top Scoring"
                      labelId="topScorerSelectLabel"
                      value={topScorer?.teamId || ''}
                      onChange={onPoolPredictionAdd}
                    >
                      {teams.map((t) => (<MenuItem value={t.id} key={t.id}>{t.name}</MenuItem>))}
                    </Select>
                  </FormControl>
                }
              </div>
              <div className={classes.poolPrediction}>
                <span>Competition Winner:</span>
                {competition.date?.toDate() < new Date() && championTeam ?
                  <div className={classes.poolPredictionTeam}>
                    <img className={classes.poolPredictionLogo} src={`${process.env.PUBLIC_URL}/teams/logos/${championTeam.logo}`} alt={championTeam.name}/>
                    <span>{championTeam.name}</span>
                  </div>
                :
                  <FormControl sx={{ minWidth: 150 }} className={classes.poolPredictionSelect} disabled={competition.date?.toDate() < new Date()}>
                    <InputLabel id="championSelectLabel">Winner</InputLabel>
                    <Select
                      id="champion"
                      name="champion"
                      label="Champion"
                      labelId="championSelectLabel"
                      value={champion?.teamId || ''}
                      onChange={onPoolPredictionAdd}
                    >
                      {teams.map((t) => (<MenuItem value={t.id} key={t.id}>{t.name}</MenuItem>))}
                    </Select>
                  </FormControl>
                }
              </div>
              <Button onClick={() => setTab(1)} variant="contained">Next</Button>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={tab} index={1}>
          <div className={classes.fixtures}>
            <Button onClick={() => setViewFinishedFixtures(!viewFinishedFixtures)} variant="contained">{!viewFinishedFixtures ? 'View' : 'Hide'} Finished Fixtures</Button>
            <br/>
            <br/>
            {fixtures?.sort((a, b) => a.date.seconds - b.date.seconds).filter(f => viewFinishedFixtures || !hasValidResult(f)).map(fixture => (
              <div key={`fixture-${fixture.id}`} className={classes.fixture}>
                <Fixture fixture={fixture} settings={settings} pool={pool}/>
              </div>
            ))}
          </div>
        </TabPanel>
        {competition.stages.map((s, i) => (
          <TabPanel key={`stage-${s.id}`} value={tab} index={i + 2}>
            <StageSection stage={s}/>
          </TabPanel>
        ))}
      </div>
    </div>
  )
}

export default PredictionsSection
