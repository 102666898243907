import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    // maxWidth: 1200,
    width: '100%',
    margin: '0 auto',
    backgroundAttachment: 'fixed',
    background: '#1F47DD',
    // backgroundImage: `linear-gradient(to right top, rgba(63, 15, 34, 0.7), rgba(138, 37, 59, 0.7), rgba(182, 50, 70, 0.7), rgba(214, 50, 81, 0.7)), url(${process.env.PUBLIC_URL}/pattern.jpg)`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  menuButton: {
    fontSize: 36,
  },
  title: {
    flexGrow: 1,
    '@media (max-width: 767px)': {
      textAlign: 'center',
      paddingRight: 40,
    },
  },
  titleLink: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '@media (max-width: 767px)': {
      justifyContent: 'center',
    },
  },
  logo: {
    width: 170,
    height: 'auto',
  },
  container: {
    minHeight: '100vh',
  },
  appBarWrapper: {
    zIndex: 1,
  },
  appBar: {
    backgroundColor: '#10303E',
    boxShadow: 'none',
    color: '#ffffff',
  },
}))
