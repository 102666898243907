import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Hero } from 'components/layout'
import { useUser } from 'hooks'

const HomePage = () => {
  const navigate = useNavigate()
  const { isLoggedIn, loading, pools } = useUser()

  const onActionClick = () => {
    if (!isLoggedIn) {
      navigate('/login')
    } else if (pools && pools.length === 1) {
      navigate(`/pool/${pools[0]}`)
    } else {
      navigate('/pools')
    }
  }

  if (loading) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        <img
          src={`${process.env.PUBLIC_URL}/logo375.png`}
          alt="loading-logo"
          width="150"
          style={{ animation: 'jump 1s linear infinite' }}
        />
      </div>
    )
  }

  return (
    <Hero
      actionText={isLoggedIn ? 'Continue' : 'Get Started'}
      actionClick={onActionClick}
    />
  )
}

export default HomePage
