import React, { useState } from 'react'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button } from '@mui/material'
import { useForm } from 'react-hook-form'

import { loginWithEmailAndPassword } from 'mutations'
import { Input } from 'components/form'

const prettyErrorMessage = error => {
  const { code, message } = error
  if (code === 'auth/wrong-password') {
    return 'You have entered an invalid email or password'
  }
  if (code === 'auth/user-not-found') {
    return 'Could not find a user with that email'
  }
  if (code === 'auth/too-many-requests') {
    return 'Too many requests right now'
  }
  if (code === 'auth/network-request-failed') {
    return 'Network request failed'
  }
  if (code === 'auth/timeout') {
    return 'Network request timed out'
  }
  return message
}

const schema = Yup.object({
  emailAddress: Yup.string()
    .email('Email address is not valid')
    .required('Email address is required'),
  password: Yup.string().required('Password is required'),
}).required()

const LoginForm = () => {
  const { control, handleSubmit } = useForm({
    defaultValues: {
      emailAddress: '',
      password: '',
    },
    resolver: yupResolver(schema),
  })

  const [submitError, setSubmitError] = useState()

  const onSubmit = async (formValues, setSubmitError) => {
    const { emailAddress, password } = formValues
    const { error } = await loginWithEmailAndPassword(emailAddress, password)
    if (error) setSubmitError(error)
  }

  return (
    <form onSubmit={handleSubmit(values => onSubmit(values, setSubmitError))}>
      <Input
        name="emailAddress"
        label="Email address"
        type="email"
        control={control}
        fullWidth
        required
      />
      <br />
      <br />
      <Input
        name="password"
        label="Password"
        type="password"
        control={control}
        fullWidth
        required
      />
      {submitError && (
        <p style={{ color: 'red', textAlign: 'center' }}>
          {prettyErrorMessage(submitError)}
        </p>
      )}
      <div style={{ textAlign: 'center', marginTop: 15 }}>
        <Button variant="contained" size="large" type="submit">
          Login
        </Button>
      </div>
    </form>
  )
}

export default LoginForm
