import React from 'react'
import GroupSection from './GroupSection'
import { usePool } from 'hooks'
import { useStyles } from './StageSection.styles'

const StageSection = ({ stage }) => {
  const { fixtures } = usePool()
  const classes = useStyles()

  if (!fixtures.find(f => f.stageId === stage.id)) {
    return <p style={{ color: '#2e2e2e'}}>Fixtures are not ready yet!</p>
  }

  return (
    <div className={classes.container}>
      {stage.groups.map(group => <GroupSection key={`group-${group.id}`} group={group} stageId={stage.id}/>)}
    </div>
  )
}

export default StageSection
