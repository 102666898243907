
import React from 'react'
import { useApp, usePool } from 'hooks'

import { Avatar, Button, Table,TableBody, TableCell, TableContainer,TableHead, TableRow } from '@mui/material'

import { useStyles } from './UsersTable.styles'
import { approveUserInPool, unapproveUserInPool } from 'mutations'

const UsersTable = () => {
  const classes = useStyles()
  const { users, pool } = usePool()
  const { showAlert } = useApp()

  const onUnapproveUser = (id) => {
    unapproveUserInPool(id, pool.id).then(() => {
      showAlert({ message: 'User was un-approved' })
    })
  }

  const onApproveUser = (id) => {
    approveUserInPool(id, pool.id).then(() => {
      showAlert({ message: 'User was approved' })
    })
  }

  return (
      <TableContainer className={classes.container}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>User</TableCell>
              <TableCell>Email</TableCell>
              <TableCell style={{ width: 160, textAlign: 'center' }}>Approved</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map(row => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar src={row.photoURL} style={{ marginRight: 15 }}/>
                    {row.userName}
                  </div>
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.email}
                </TableCell>
                <TableCell component="th" scope="row" style={{ width: 160, textAlign: 'center' }}>
                  {pool?.approvedUsers?.includes(row.id) ?
                    <Button onClick={() => onUnapproveUser(row.id)} color="error">Remove</Button>
                  :
                    <Button onClick={() => onApproveUser(row.id)} variant="contained">Approve</Button>
                  }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
  )
}

export default UsersTable