import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    margin: '0 auto 20px',
    border: 0,
    outline: 'none',
    boxShadow: '0 4px 0 rgba(0,0,0,.075),inset 0 -4px 0 rgba(0,0,0,.075)',
    background: '#ffffff',
    width: 'auto',
    padding: 20
  }
}))