import React, { createContext, useEffect, useState } from 'react'

import { firebaseAuth, doc } from 'firebase-client/client'
import { setDoc, getDoc } from 'firebase/firestore'

export const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState({ isLoggedIn: false, loading: true })

  useEffect(() => {
    const unsubscribeAuthStageChange = firebaseAuth.onAuthStateChanged(
      async authUser => {
        if (!authUser) {
          setUser({ isLoggedIn: false, loading: false })
          return
        }
        const userRef = doc('users', authUser.uid)
        const existingUserDoc = await getDoc(userRef)
        const existingUserName = existingUserDoc?.data()?.userName
        const existingPhotoURL = existingUserDoc?.data()?.photoURL
        await setDoc(
          userRef,
          {
            email: authUser.email,
            userName: existingUserName || authUser.displayName,
            photoURL: existingPhotoURL || authUser.photoURL,
          },
          { merge: true },
        )
        const userDoc = await getDoc(userRef)
        setUser({
          id: authUser.uid,
          ...userDoc.data(),
          isLoggedIn: true,
          loading: false,
        })
      },
    )

    return () => {
      unsubscribeAuthStageChange()
    }
  }, [])

  return <AuthContext.Provider value={user}>{children}</AuthContext.Provider>
}
