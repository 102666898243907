import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles(() => ({
  container: {
    border: 0,
    outline: 'none',
    // borderRadius: 12,
    boxShadow: '0 4px 0 rgba(0,0,0,.075),inset 0 -4px 0 rgba(0,0,0,.075)',
    background: '#ffffff',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    minWidth: 0,
    wordWrap: 'break-word',
    color: '#2e2e2e',
    padding: '5px'
  },
  top: {
    fontSize: 11
  },
  main: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px',
    "@media (max-width: 767px)": {
      padding: '8px 4px'
    }
  },
  homeTeam: {
    display: 'flex',
    alignItems: 'center',
    flex: '1 1 0',
    maxWidth: '100%',
    padding: '0 14px',
    minWidth: '80px',
    flexDirection: 'row-reverse',
    "@media (max-width: 767px)": {
      padding: '0 6px',
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  },
  homeTeamLogo: {
    flex: 'none',
    height: '2em',
    verticalAlign: 'middle',
    filter: 'drop-shadow(1px 1px 2px rgb(0 0 0 / 0.2))',
    "@media (max-width: 767px)": {
      height: '2.2em',
      marginBottom: 5
    }
  },
  homeTeamName: {
    textAlign: 'right',
    marginLeft: 0,
    marginRight: 14,
    flex: '1 1 0',
    lineHeight: 1.1,
    minWidth: 0,
    hyphens: 'auto',
    fontWeight: 600,
    // fontFamily: 'Kanit, sans-serif',
    "@media (max-width: 767px)": {
      marginRight: 6,
      fontSize: 14,
      textAlign: 'left',
      fontWeight: 500
    }
  },
  awayTeam: {
    display: 'flex',
    alignItems: 'center',
    flex: '1 1 0',
    maxWidth: '100%',
    padding: '0 14px',
    minWidth: '80px',
    "@media (max-width: 767px)": {
      padding: '0 6px',
      flexDirection: 'column',
      alignItems: 'flex-end'
    }
  },
  awayTeamLogo: {
    flex: 'none',
    height: '2em',
    verticalAlign: 'middle',
    filter: 'drop-shadow(1px 1px 2px rgb(0 0 0 / 0.4))',
    "@media (max-width: 767px)": {
      height: '2.2em',
      marginBottom: 5
    }
  },
  awayTeamName: {
    marginLeft: 14,
    textAlign: 'left',
    flex: '1 1 0',
    lineHeight: 1.1,
    minWidth: 0,
    hyphens: 'auto',
    fontWeight: 600,
    // fontFamily: 'Kanit, sans-serif',
    "@media (max-width: 767px)": {
      marginLeft: 6,
      fontSize: 14,
      textAlign: 'right',
      fontWeight: 500
    }
  },
  badge: {
    height: 30,
    borderRadius: '50%',
    width: 30,
    fontWeight: 'bold',
    color: '#ffffff',
    boxShadow: '1px 1px 2px rgba(0,0,0,.2)',
    top: 15,
    right: 15,
    "@media (max-width: 767px)": {
      top: 27,
      right: '50%',
      width: 20,
      height: 20,
      fontSize: 10,
      boxShadow: 'none'
    }
  },
  badgeMax: {
    height: 30,
    borderRadius: '50%',
    width: 30,
    fontWeight: 'bold',
    color: '#ffffff',
    boxShadow: '1px 1px 2px rgba(0,0,0,.2)',
    top: 15,
    right: 15,
    border: '2px solid #FDBD01',
    "@media (max-width: 767px)": {
      top: 27,
      right: '50%',
      width: 20,
      height: 20,
      fontSize: 10,
      boxShadow: 'none'
    }
  },
  badgeExpired: {
    height: 30,
    borderRadius: '50%',
    width: 30,
    color: '#ffffff',
    background: '#aaa',
    fontSize: 20,
    boxShadow: '1px 1px 2px rgba(0,0,0,.2)',
    top: 15,
    right: 15,
    "@media (max-width: 767px)": {
      top: 27,
      right: '50%',
      width: 20,
      height: 20,
      fontSize: 10,
      boxShadow: 'none'
    }
  },
  badgeWrong: {
    height: 30,
    borderRadius: '50%',
    width: 30,
    color: '#ffffff',
    background: 'red',
    boxShadow: '1px 1px 2px rgba(0,0,0,.2)',
    top: 15,
    right: 15,
    "@media (max-width: 767px)": {
      top: 27,
      right: '50%',
      width: 20,
      height: 20,
      fontSize: 10,
      boxShadow: 'none'
    }
  }
}))
