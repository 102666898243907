import { addDoc, updateDoc, getDoc } from 'firebase/firestore'
import { collection, doc } from 'firebase-client/client'

export const addFixture = async (values) => {
  const { homeTeam, awayTeam, date, ...payload } = values
  const parsedPayload = JSON.parse(JSON.stringify(payload))
  parsedPayload.homeTeam = doc(`teams/${homeTeam.id}`)
  parsedPayload.awayTeam = doc(`teams/${awayTeam.id}`)
  parsedPayload.date = date
  const docRef = await addDoc(collection('fixtures'), parsedPayload)
  return { ...values, id: docRef.id }
}

export const updateFixture = async ({ fixtureId, values }) => {
  const { homeTeam, awayTeam, date, ...payload } = values
  const parsedPayload = JSON.parse(JSON.stringify(payload))
  parsedPayload.homeTeam = doc(`teams/${homeTeam.id}`)
  parsedPayload.awayTeam = doc(`teams/${awayTeam.id}`)
  parsedPayload.date = date
  const fixtureRef = doc(`fixtures/${fixtureId}`)
  await updateDoc(fixtureRef, parsedPayload)
  const fixture = await getDoc(fixtureRef)
  return { id: fixture.id, ...fixture.data() }
}
