import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button } from '@mui/material'
import { useForm } from 'react-hook-form'

import { Input, Select, GeneralError } from 'components/form'

const schema = Yup.object({
  name: Yup.string().required('Pool name is required').test('len', 'Maximum 24 characters', val => val.length < 24),
  competitionId: Yup.string().required('Competition is required'),
}).required()

const AddPoolForm = ({ onSubmit, competitions, submitError }) => {
  const { control, handleSubmit, formState, setError } = useForm({
    defaultValues: {
      name: '',
      competitionId: competitions.length > 0 ? competitions[0].id : '',
    },
    resolver: yupResolver(schema),
  })

  useEffect(() => {
    if (submitError) {
      setError('generalError', {
        type: 'SERVER_ERROR',
        message: submitError.message,
      })
    }
  }, [submitError, setError])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Input
        name="name"
        label="Pool name"
        control={control}
        fullWidth
        autoFocus
      />
      <br />
      <br />
      <Select
        name="competitionId"
        label="Competition"
        control={control}
        options={competitions.map(({ id, name }) => ({
          value: id,
          label: name,
        }))}
        fullWidth
      />
      <br/>
      <br/>
      <GeneralError control={control} />
      <div style={{ textAlign: 'center' }}>
        <Button
          variant="contained"
          size="large"
          type="submit"
          disabled={formState.isSubmitting}
        >
          Create
        </Button>
      </div>
    </form>
  )
}

AddPoolForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  competitions: PropTypes.array,
  submitError: PropTypes.object,
}

AddPoolForm.defaultProps = {
  competitions: [],
  submitError: undefined,
}
export default AddPoolForm
