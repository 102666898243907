import React from 'react'

import { Badge } from '@mui/material'
import { timestampToDate } from 'firebase-client/utils'
import FixtureForm from './FixtureForm'
import FixtureResult from './FixtureResult'

import { MdOutlineTimerOff } from 'react-icons/md'
import { getPredictionPoints, hasValidResult } from 'utils/fixture'

import { useStyles } from './Fixture.styles'

const Fixture = ({ fixture, pool, settings }) => {

  const classes = useStyles()

  const { prediction, status } = fixture

  const hasPrediction = hasValidResult(prediction)
  const { total, isMax } = getPredictionPoints(prediction, fixture, settings)

  return (
    <div className={classes.container}>
      {pool && !!total && <Badge color="primary" badgeContent={total} classes={{ badge: isMax ? classes.badgeMax : classes.badge }}/>}
      {pool && !total && status === 'finished' && !hasPrediction && <Badge badgeContent={<MdOutlineTimerOff/>} classes={{ badge: classes.badgeExpired }}/>}
      {pool && !total && status === 'finished' && hasPrediction && <Badge badgeContent="0" classes={{ badge: classes.badgeWrong }}/>}
      <div className={classes.top}>{timestampToDate(fixture.date)}</div>
      <div className={classes.main}>
        <div className={classes.homeTeam}>
          <img className={classes.homeTeamLogo} src={`${process.env.PUBLIC_URL}/teams/logos/${fixture.homeTeam.logo}`} alt={fixture.homeTeam.name}/>
          <span className={classes.homeTeamName}>{fixture.homeTeam.name}</span>
        </div>
        {pool ?
          <div className={classes.score}>
            {status !== 'scheduled' ?
              <FixtureResult fixture={fixture} showPrediction/>
            :
              <FixtureForm fixture={fixture}/>
            }
          </div>
        :
          <FixtureResult fixture={fixture}/>
        }
        <div className={classes.awayTeam}>
          <img className={classes.awayTeamLogo} src={`${process.env.PUBLIC_URL}/teams/logos/${fixture.awayTeam.logo}`} alt={fixture.awayTeam.name}/>
          <span className={classes.awayTeamName}>{fixture.awayTeam.name}</span>
        </div>
      </div>
      <div className={classes.bottom}><span/></div>
    </div>
  )
}

export default Fixture
