export const resizeUploadedImage = (imageFile, imageResizeWidth, callback) => {
  if (!imageFile) {
    callback();
    return;
  }

  const { type: mimeType } = imageFile;
  // eslint-disable-next-line no-undef
  const fileReader = new FileReader();
  fileReader.onabort = () => console.log('file reading was aborted');
  fileReader.onerror = () => console.log('file reading has failed');
  fileReader.onload = () => {
    const imageAsBase64 = fileReader.result;
    // eslint-disable-next-line no-undef
    const image = new Image();
    image.onload = function () {
      if (image.width <= imageResizeWidth) {
        callback(imageAsBase64);
        return;
      }
      const canvas = document.createElement('canvas');
      canvas.width = imageResizeWidth;
      // eslint-disable-next-line no-bitwise
      canvas.height = ~~(image.height * (imageResizeWidth / image.width));
      const context = canvas.getContext('2d', { alpha: false });
      if (!context) {
        callback(imageAsBase64);
        return;
      }
      context.drawImage(image, 0, 0, canvas.width, canvas.height);

      const resizedImageAsBase64 = canvas.toDataURL(mimeType);
      callback(resizedImageAsBase64);
    };
    image.src = imageAsBase64;
  };
  fileReader.readAsDataURL(imageFile);
}

export const readUploadedImage = (imageFile, callback) => {
  // eslint-disable-next-line no-undef
  const fileReader = new FileReader();
  fileReader.onabort = () => console.log('file reading was aborted');
  fileReader.onerror = () => console.log('file reading has failed');
  fileReader.onload = () => {
    callback(fileReader.result);
  }
  fileReader.readAsDataURL(imageFile);
}