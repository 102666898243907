import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { RegisterDialog } from 'pages/register/components'
import { useApp, useQuery, useUser } from 'hooks'

const RegisterPage = () => {
  const navigate = useNavigate()
  const query = useQuery()
  const { showAlert } = useApp()
  const { isLoggedIn, email, userName } = useUser()

  useEffect(() => {
    if (isLoggedIn) {
      showAlert({ message: `Welcome ${userName || email}!` })
      navigate('/pools')
    }
  }, [isLoggedIn])


  return (
  <RegisterDialog
    open
    onLoginClick={() => navigate(`/login?${query.toString()}`)}
  />
)}

export default RegisterPage