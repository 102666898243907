import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles(theme => ({
  root: {
    flex: '1 0 100%',
  },
  hero: {
    minHeight: '100vh',
    flex: '0 0 auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    color: '#ffffff',
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    whiteSpace: 'nowrap',
    fontFamily: 'Kumbh Sans',
    fontSize: 'calc(1.2em + 4vw)',
    marginBottom: '0',
    fontWeight: 700,
  },
  subtitle: {
    whiteSpace: 'nowrap',
    fontFamily: 'Kumbh Sans',
    fontSize: 'calc(1em + 2vw)',
    marginTop: '-12px',
    marginBottom: '20px',
    fontWeight: 300,
  },
  headline: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    marginTop: theme.spacing(),
    maxWidth: 800,
    textAlign: 'center',
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: 'calc(0.6em + 1vw)',
    marginBottom: '0px',
    letterSpacing: '1px',
  },
  content: {},
  button: {
    letterSpacing: '2px',
    fontSize: '1.2em',
    fontWeight: 'bold',
  },
  buttonMargin: {
    marginTop: theme.spacing(3),
  },
}))
