import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material'
import { HiMenu } from 'react-icons/hi'
import PWAPrompt from 'react-ios-pwa-prompt'

import { useApp, useUser } from 'hooks'

import { Alert } from 'components/ui'
import { TopNav } from 'components/layout'
import {
  updatePageTitle,
  updatePageDescription,
  updatePageOgURL,
} from 'utils/seo'

import { useStyles } from './Layout.styles'
import { logout } from 'mutations'

const Layout = ({ children }) => {
  const classes = useStyles()
  const { isAdmin, isLoggedIn } = useUser()
  const { pathname } = useLocation()
  const { alert, closeAlert, alertOpen, showAlert } = useApp()
  const navigate = useNavigate()
  const [drawerOpen, setDrawerOpen] = useState(false)

  const userAgent = window.navigator.userAgent.toLowerCase()
  const isFirefox = navigator.userAgent.indexOf('FxiOS') !== -1
  const isChrome = navigator.userAgent.indexOf('CriOS') !== -1
  const isEdge = navigator.userAgent.indexOf('EdgiOS') !== -1
  const isOpera = navigator.userAgent.indexOf('OPT') !== -1
  const isSafariIos =
    /iphone|ipad|ipod/.test(userAgent) &&
    !(isFirefox || isChrome || isEdge || isOpera)
  const isInStandaloneMode =
    'standalone' in window.navigator && window.navigator.standalone
  const showPWAPrompt = !(
    pathname.indexOf('/join/') === 0 ||
    pathname.indexOf('/login') === 0 ||
    pathname.indexOf('/register') === 0
  )

  useEffect(() => {
    updatePageTitle(pathname)
    updatePageDescription(pathname)
    updatePageOgURL(pathname)
  }, [pathname])

  const toggleDrawer = event => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }
    setDrawerOpen(!drawerOpen)
  }

  const onPromptClose = () => {
    localStorage?.setItem('hide-install-prompt', true);
  }

  const hidePrompt = !!localStorage?.getItem('hide-install-prompt')

  return (
    <>
      <div className={classes.root}>
        <div>
          <span
            style={{
              position: 'fixed',
              right: 3,
              top: 3,
              fontSize: 8,
              zIndex: 1200,
              color: 'grey',
            }}
          >
            v1.33
          </span>
          <AppBar className={classes.appBar}>
            <Toolbar>
              <Box
                sx={{
                  display: { xs: 'block', sm: 'none' },
                  visibility: isLoggedIn ? 'visible' : 'hidden',
                }}
              >
                <IconButton
                  edge="start"
                  className={classes.menuButton}
                  color="inherit"
                  aria-label="menu"
                  onClick={toggleDrawer}
                >
                  <HiMenu />
                </IconButton>
              </Box>
              <Typography component="h1" className={classes.title}>
                <Link to="/" className={classes.titleLink}>
                  <img
                    src={`${process.env.PUBLIC_URL}/logo.png`}
                    className={classes.logo}
                    width="520"
                    height="112"
                    alt="Wibbet Logo"
                  />
                </Link>
              </Typography>
              <TopNav />
            </Toolbar>
          </AppBar>
        </div>
        <div className={classes.container}>{children}</div>
      </div>
      {isLoggedIn && (
        <Drawer open={drawerOpen} onClose={toggleDrawer}>
          <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={toggleDrawer}
            onKeyDown={toggleDrawer}
          >
            <List>
              {isAdmin && (
                <ListItem disablePadding>
                  <ListItemButton onClick={() => navigate('/admin')}>
                    <ListItemText
                      primary="ADMIN"
                      primaryTypographyProps={{
                        style: {
                          fontSize: 18,
                          fontWeight: 600,
                          letterSpacing: '1.2px',
                        },
                      }}
                    />
                  </ListItemButton>
                </ListItem>
              )}
              <ListItem disablePadding>
                <ListItemButton onClick={() => navigate('/pools')}>
                  <ListItemText
                    primary="MY POOLS"
                    primaryTypographyProps={{
                      style: {
                        fontSize: 18,
                        fontWeight: 600,
                        letterSpacing: '1.2px',
                      },
                    }}
                  />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton onClick={() => navigate('/account')}>
                  <ListItemText
                    primary="ACCOUNT"
                    primaryTypographyProps={{
                      style: {
                        fontSize: 18,
                        fontWeight: 600,
                        letterSpacing: '1.2px',
                      },
                    }}
                  />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    logout()
                    navigate('/')
                    showAlert({ message: 'You have been logged out!' })
                  }}
                >
                  <ListItemText
                    primary="LOGOUT"
                    primaryTypographyProps={{
                      style: {
                        fontSize: 18,
                        fontWeight: 600,
                        letterSpacing: '1.2px',
                      },
                    }}
                  />
                </ListItemButton>
              </ListItem>
            </List>
          </Box>
        </Drawer>
      )}
      {alert && <Alert {...alert} onClose={closeAlert} open={alertOpen} />}
      {!hidePrompt && isSafariIos && !isInStandaloneMode && showPWAPrompt &&
        <PWAPrompt
          copyBody="Add the Wibbet app to your home screen"
          copyShareButtonLabel="1) Press the 'Share' button"
          onClose={onPromptClose}
          debug
        />
      }
    </>
  )
}

export default Layout
