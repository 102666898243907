import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles(() => ({
  shareDialogContent: {
    textAlign: 'center'
  },
  shareLinkButton: {
    marginBottom: 15
  },
  shareItem: {
    margin: 6
  }
}))
