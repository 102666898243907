import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles(() => ({
  pageContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100% - 64px)',
    position: 'relative',
    textAlign: 'center',
    marginTop: 64,
    color: '#ffffff',
    background: '#ECEBF3',
    '@media (max-width: 767px)': {
      marginTop: 55,
      paddingBottom: 64,
    },
  },
  headerContainer: {
    // boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    // background: '#2e2e2e'
    background: '#1F47DD',
    boxShadow:
      'rgba(230, 175, 46, 0.4) 0px 5px, rgba(230, 175, 46, 0.3) 0px 10px',
    paddingBottom: 20,
    marginBottom: 10,
    '@media (max-width: 767px)': {
      paddingBottom: 10,
    },
  },
  headerTop: {
    display: 'flex',
    padding: '0 25px',
  },
  pageTitle: {
    fontFamily: 'Kanit, sans-serif',
    textTransform: 'uppercase',
    letterSpacing: '2px',
    color: '#ffffff',
    fontWeight: 'bold',
    padding: '20px 10px 10px',
    textShadow: '1px 2px 2px #4e4e4e',
    '@media (max-width: 767px)': {
      fontSize: 26,
    },
  },
  pageSubtitle: {
    fontFamily: 'Kanit, sans-serif',
    textTransform: 'uppercase',
    letterSpacing: '2px',
    color: '#2e2e2e',
    fontWeight: 'bold',
    padding: '20px 10px 10px',
  },
  poolUsers: {
    display: 'flex',
  },
  poolTabs: {
    width: 'auto',
    color: '#2e2e2e',
    margin: '0 auto',
    '& .MuiTabs-indicator': {
      height: 4,
    },
  },
  content: {
    flexGrow: 1
  },
  bottomNavigationAction: {
    color: '#ffffff',
    '&.Mui-selected': {
      color: '#3ecc51',
    },
  },
  bottomNavigationActionIcon: {
    fontSize: 26,
    marginBottom: 6,
  },
}))
