import React, { useCallback } from 'react'
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Button,
} from '@mui/material'
import { deletePool } from 'mutations'

const DeletePoolDialog = ({
  isOpen,
  poolId,
  poolName,
  setDeleteDialogProps,
  closable = true,
}) => {
  const handleClose = useCallback(
    () =>
      setDeleteDialogProps({
        isOpen: false,
        poolId: null,
        poolName: null,
      }),
    [setDeleteDialogProps],
  )

  const handleDelete = useCallback(async () => {
    await deletePool(poolId)
    handleClose()
  }, [handleClose, poolId])

  return (
    <Dialog
      open={isOpen}
      onClose={() => closable && handleClose()}
      aria-labelledby="delete-dialog-title"
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle id="delete-dialog-title">{`Delete pool: ${poolName}`}</DialogTitle>

      <DialogContent>
        <DialogContentText id="delete-dialog-description">
          Are you sure you want to delete this pool?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>No</Button>
        <Button onClick={handleDelete}>Yes</Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeletePoolDialog
