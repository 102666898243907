import { makeStyles } from '@mui/styles'

export const useStyles = makeStyles(() => ({
  pageContainer: {
    height: 'calc(100% - 64px)',
    position: 'relative',
    textAlign: 'center',
    paddingTop: 64,
    color: '#ffffff',
    '@media (max-width: 767px)': {
      marginTop: 55,
    },
  },
  pageTitle: {
    fontWeight: 'bold',
    color: '#ffffff',
    marginTop: 20,
    marginBottom: 20,
  },
  adminList: {
    width: '100%',
    backgroundColor: '#ffffff',
    color: '#2e2e2e',
    // borderRadius: 6,
  },
  adminListItem: {
    paddingRight: 100,
  },
}))
