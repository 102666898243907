import React from 'react'
import PropTypes from 'prop-types'
import { Checkbox as MuiCheckbox } from '@mui/material'
import { Controller } from 'react-hook-form'

const Checkbox = ({ control, name, ...rest }) => (
  <Controller
    name={name}
    control={control}
    render={({ field: { value, ...field } }) => (
      <MuiCheckbox checked={value} {...field} {...rest} />
    )}
  />
)

Checkbox.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
}

export default Checkbox
