import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  tabContainer: {
    padding: '20px 40px',
    "@media (max-width: 767px)": {
      padding: '20px'
    }
  },
  tabTitle: {
    fontWeight: 'bold',
    fontFamily: 'Kanit, sans-serif',
    textTransform: 'uppercase',
    color: '#2e2e2e',
    marginBottom: 20,
  },
  tabSubtitle: {
    color: '#2e2e2e',
    marginBottom: 20,
  },
  poolPredictions: {
    outline: 'none',
    // borderRadius: 12,
    boxShadow: '0 4px 0 rgba(0,0,0,.075),inset 0 -4px 0 rgba(0,0,0,.075)',
    background: '#ffffff',
    color: '#2e2e2e',
    maxWidth: 500,
    margin: '20px auto',
    padding: '15px 25px'
  },
  poolPrediction: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '15px 0'
  },
  poolPredictionTeam: {
    minWidth: 150,
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 'bold'
  },
  poolPredictionLogo: {
    width: 40,
    marginRight: 15
  },
  poolPredictionSelect: {
    marginLeft: 'auto'
  },
  stageTabs: {
    position: 'sticky',
    top: 0,
    background: '#ECEBF3',
    zIndex: 2,
    '& .MuiTabs-flexContainer': {
      justifyContent: 'center',
      "@media (max-width: 920px)": {
        justifyContent: 'flex-start'
      }
    }
  },
  fixtures: {
    flex: 1,
    padding: 20,
    maxWidth: 600,
    margin: '0 auto'
  },
  fixture: {
    marginBottom: 15
  }
}));