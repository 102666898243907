import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { useApp, usePool, useUser } from 'hooks'
import { addUserToPool } from 'mutations'
import { Button, useMediaQuery } from '@mui/material'
import WebFont from 'webfontloader'
import { shortTimestampToDate } from 'firebase-client/utils'
import { LoginDialog } from 'pages/login/components'
import { RegisterDialog } from 'pages/register/components'
import { updatePageTitle, updatePageDescription, updatePageOgURL } from 'utils/seo'

const JoinPage = () => {
  const { poolId } = useParams()
  const navigate = useNavigate()
  const { isLoggedIn, id, pools } = useUser()
  const { pathname } = useLocation()
  const { showAlert } = useApp()
  const { pool, loaded } = usePool()
  const [loginDialogOpen, setLoginDialogOpen] = useState(false)
  const [registerDialogOpen, setRegisterDialogOpen] = useState(false)
  const isMobile = useMediaQuery('(max-width:767px)')


  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Kanit:400,700'],
      },
    })
  }, [])

  useEffect(() => {
    if (isLoggedIn) {
      if (pools?.includes(poolId)) {
        navigate(`/pool/${poolId}`)
      } else {
        addUserToPool(id, poolId).then(() => {
          navigate(`/pool/${poolId}`)
        })
      }
      showAlert({ message: `Welcome!` })
    }
  }, [isLoggedIn])


  useEffect(() => {
    if (pool) {
      updatePageTitle(pathname, pool)
      updatePageDescription(pathname, pool)
      updatePageOgURL(pathname)
    }
  }, [pool])

  const onJoinPool = () => {
    setLoginDialogOpen(true)
  }

  if (!pool || !loaded || isLoggedIn) {
    return null
  }

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', height: '100vh' }}>
        <img src={`${process.env.PUBLIC_URL}/logo375.png`} alt="loading-logo" width="150" />
        <h1 style={{ fontSize: isMobile ? 38 : 60, color: '#ffffff', marginBottom: 12, marginTop: 12, fontFamily: 'Kanit, sans-serif', textTransform: 'uppercase', fontWeight: 'bold', textShadow: '1px 2px 2px #4e4e4e' }}>{pool.name}</h1>
        <p style={{ color: '#bfbfbf', marginBottom: 40, marginTop: 0 }}>{`Pool created by ${pool.ownerUserName || 'Anonymous'} on ${shortTimestampToDate(pool.created)}`}</p>
        <Button onClick={onJoinPool} variant="contained" style={{ fontSize: '1.4em', width: 300, fontWeight: 600, letterSpacing: 2 }}>Join Pool</Button>
      </div>
      <LoginDialog
        open={loginDialogOpen}
        onClose={() => {
          setLoginDialogOpen(false)
        }}
        onRegisterClick={() => {
          setRegisterDialogOpen(true)
          setLoginDialogOpen(false)
        }}
      />
      <RegisterDialog
        open={registerDialogOpen}
        onClose={() => {
          setRegisterDialogOpen(false)
        }}
        onLoginClick={() => {
          setRegisterDialogOpen(false)
          setLoginDialogOpen(true)
        }}
      />
    </div>
  )
}

export default JoinPage
