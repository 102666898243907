import React from 'react'
import {
  Dialog as MUIDialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import { IoClose } from 'react-icons/io5'

const Dialog = ({
  children,
  open,
  onClose,
  title = '',
  subtitle = '',
  closable = true,
  ...props
}) => (
  <MUIDialog
    open={open}
    onClose={closable ? onClose : undefined}
    aria-labelledby="dialog-title"
    {...props}
  >
    <DialogTitle id="dialog-title">
      {title}
      {closable && onClose &&
        <div
          aria-label="close"
          onClick={onClose}
          style={{
            position: 'absolute',
            right: 10,
            top: 10,
            cursor: 'pointer',
          }}
        >
          <IoClose />
        </div>
      }
    </DialogTitle>
    <DialogContent>
      {subtitle && <DialogContentText>{subtitle}</DialogContentText>}
      {children}
    </DialogContent>
  </MUIDialog>
)

export default Dialog
