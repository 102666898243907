import React, { createContext, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useUser } from 'hooks'
import { getCompetition, getCompetitionFixtures, getUsers, useGetPool, getPoolPredictions, getTeams } from 'queries'
import { updatePrediction as updateFirebasePrediction, addPrediction as addFirebasePrediction } from 'mutations'

export const PoolContext = createContext()

export const PoolProvider = ({ children }) => {
  const { poolId } = useParams()
  const { id } = useUser()

  const [competition, setCompetition] = useState()
  const [fixtures, setFixtures] = useState([])
  const [users, setUsers] = useState([])
  const [teams, setTeams] = useState([])
  const [poolPredictions, setPoolPredictions] = useState({})
  const [loaded, setLoaded] = useState()
  const [pool] = useGetPool(poolId)

  const settings = pool?.settings || competition?.settings

  const addPrediction = (payload) => addFirebasePrediction({ ...payload, created: new Date(), updated: new Date() }).then((prediction) => {
    if (prediction.fixtureId) {
      setFixtures(fixtures.map(f => f.id === prediction.fixtureId ? { ...f, prediction: { ...f.prediction, ...prediction } } : f ))
    } else if (payload.name === 'topScorer' || payload.name === 'champion') {
      setPoolPredictions({ ...poolPredictions, [payload.name]: payload })
    }
    return prediction
  })

  const updatePrediction = (payload) => updateFirebasePrediction({ ...payload, updated: new Date() }).then((prediction) => {
    if (prediction.fixtureId) {
      setFixtures(fixtures.map(f => f.id === prediction.fixtureId ? { ...f, prediction: { ...f.prediction, ...prediction } } : f ))
    } else if (prediction.name === 'topScorer' || prediction.name === 'champion') {
      setPoolPredictions({ ...poolPredictions, [prediction.name]: payload.values })
    }
    return prediction
  })

  useEffect(() => {
    if (pool && pool.id) {
      getUsers(pool.id).then(setUsers)
      getCompetition(pool.competitionId).then(setCompetition)
      Promise.allSettled([getPoolPredictions(pool.id), getTeams(), getCompetitionFixtures(pool.competitionId)]).then(([predictionsResult, teamsResult, fixturesResult]) => {
        const predictions = predictionsResult.status === 'fulfilled' ? predictionsResult.value : []
        const teams = teamsResult.status === 'fulfilled' ? teamsResult.value : []
        const fixtures = fixturesResult.status === 'fulfilled' ? fixturesResult.value : []
        setPoolPredictions({
          topScorer: predictions.find(p => p.name === 'topScorer' && p.userId === id),
          champion: predictions.find(p => p.name === 'champion' && p.userId === id),
          topScorerUserPredictions: predictions.filter(p => p.name === 'topScorer'),
          championUserPredictions: predictions.filter(p => p.name === 'champion')
        })
        setTeams(teams.filter(t => fixtures.find(f => f.homeTeam.id === t.id)))
        setFixtures(fixtures.map((f) => ({
          ...f,
          prediction: predictions.find(p => p.fixtureId === f.id && p.userId === id),
          userPredictions: predictions.filter(p => p.fixtureId === f.id && f.status === 'finished'),
          homeTeam: teams.find(t => t.id === f.homeTeam.id),
          awayTeam: teams.find(t => t.id === f.awayTeam.id),
        })))
        setLoaded(true)
      })
    }
  }, [pool])

  const value = useMemo(() => ({ pool, competition, fixtures, users, poolPredictions, teams, loaded, addPrediction, updatePrediction, settings }), [pool, competition, fixtures, users, poolPredictions, teams, settings, loaded])

  // console.log(value)

  return <PoolContext.Provider value={value}>{children}</PoolContext.Provider>
}
