import React, { useEffect } from 'react'

import { Button, Typography } from '@mui/material'
import WebFont from 'webfontloader'

import { useStyles } from './Hero.styles'

const Hero = ({ imageSrc, actionText, actionClick, children }) => {
  const classes = useStyles({ imageSrc })

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Kumbh Sans:300,700'],
      },
    })
  }, [])

  return (
    <div className={classes.hero}>
      <div className={classes.content}>
        <div className={classes.text}>
          <Typography
            align="center"
            variant="h4"
            component="h1"
            color="inherit"
            gutterBottom
            className={classes.title}
          >
            EURO2024
          </Typography>
          <Typography
            variant="h5"
            component="h2"
            color="inherit"
            gutterBottom
            className={classes.subtitle}
          >
            GERMANY
          </Typography>
          <Typography
            variant="h5"
            component="h3"
            color="inherit"
            gutterBottom
            className={classes.headline}
          >
            Start your own prediction pool with friends
          </Typography>
          {actionClick && (
            <div className={classes.buttonMargin}>
              <Button
                onClick={actionClick}
                color="primary"
                variant="contained"
                size="large"
                className={classes.button}
              >
                {actionText}
              </Button>
            </div>
          )}
        </div>
        {children}
      </div>
    </div>
  )
}

export default Hero
