import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { isValid } from 'date-fns'
// import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'
import { DateTimePicker as MuiDateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { Timestamp } from 'firebase/firestore'

import { Controller } from 'react-hook-form'
import { TextField } from '@mui/material'

const DateTimePicker = ({
  name,
  control,
  errorMessage,
  className,
  value,
  timezone,
  inputProps,
  open,
  ...datePickerProps
}) => {
  const handleDateChange = (date, onChange) => {
    if (isValid(date)) {
      const timestamp = Timestamp.fromDate(date)
      onChange(timestamp)
    }

  }
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState }) => (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <MuiDateTimePicker
            className={className}
            error={Boolean(errorMessage)}
            onChange={onChange}
            value={value ?? new Date()}
            renderInput={(params) => <TextField {...params} {...inputProps}/>}
            {...datePickerProps}
          />
        </LocalizationProvider>
      )}
    />
  );
}

DateTimePicker.propTypes = {
  className: PropTypes.string,
  errorMessage: PropTypes.string,
  value: PropTypes.string,
  timezone: PropTypes.string,
  inputProps: PropTypes.object,
  open: PropTypes.bool
};

DateTimePicker.defaultProps = {
  errorMessage: '',
  className: '',
  value: '',
  timezone: '',
  inputProps: {},
  open: false
};

export default DateTimePicker;
