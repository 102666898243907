
import React, { useEffect, useState } from 'react'
import { useApp, usePool, useUser } from 'hooks'

import { Avatar, Badge, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { getPredictionPoints, hasValidResult } from 'utils/fixture'
import { IoIosPodium } from 'react-icons/io'

import { useStyles } from './StandingsTable.styles'
import { approveUserInPool } from 'mutations'
import { GiLaurelCrown } from 'react-icons/gi'
import { shortTimestampToDate } from 'firebase-client/utils'

const StandingsTable = ({ users, showPredictionsPerFixture, version }) => {
  const classes = useStyles()
  const { id } = useUser()
  const { competition, fixtures, settings, poolPredictions, pool, teams } = usePool()
  const [standings, setStandings] = useState([])
  const { showAlert } = useApp()
  const competitionStarted = competition.date?.toDate() < new Date()
  const [selectedRow, setSelectedRow] = useState(-1)

  // eslint-disable-next-line no-underscore-dangle
  const sortedFinishedFixtures = fixtures?.filter(f => f.status === 'finished')?.sort((a, b) => b.date._compareTo(a.date)) || []

  useEffect(() => {
    const finishedFixtures = sortedFinishedFixtures.filter(f => hasValidResult(f))
    const standings = users.map(u => ({ id: u.id, userName: u.userName, previousResults: u.previousResults, photoURL: u.photoURL, exactScoreline: 0, goalDifference: 0, outcome: 0, champion: 0, topScorer: 0, total: 0 }))
    finishedFixtures.forEach((f) => {
      if (f.userPredictions?.length) {
        users.forEach(u => {
          const prediction = f.userPredictions.find(p => p.userId === u.id)
          const index = standings.findIndex(s => s.id === u.id)
          const { exactScoreline, goalDifference, outcome, total } = getPredictionPoints(prediction, f, settings)
          standings[index].exactScoreline += exactScoreline
          standings[index].goalDifference += goalDifference
          standings[index].outcome += outcome
          standings[index].total += total
        })
      }
    })
    users.forEach(u => {
      const topScorerPrediction = poolPredictions?.topScorerUserPredictions?.find(p => p.userId === u.id)
      const championPrediction = poolPredictions?.championUserPredictions?.find(p => p.userId === u.id)
      const index = standings.findIndex(s => s.id === u.id)
      standings[index].topScorerName = topScorerPrediction && competitionStarted ? teams.find(t => t.id === topScorerPrediction.teamId)?.name : undefined
      standings[index].championName = championPrediction && competitionStarted ? teams.find(t => t.id === championPrediction.teamId)?.name : undefined
      if (topScorerPrediction && competition.topScorerTeamIds?.includes(topScorerPrediction.teamId)) {
        standings[index].topScorer += settings.topScorer
        standings[index].total += settings.topScorer
      }
      if (championPrediction && championPrediction.teamId === competition.championTeamId) {
        standings[index].champion += settings.champion
        standings[index].total += settings.champion
      }
    })
    setStandings(standings)
  }, [fixtures])

  const getCalculatedPosition = (i) => {
    if (i > 0) {
      const currentUserStandings = standings[i];
      const previousUserStandings = standings[i - 1];
      const previousCalculatedPosition = getCalculatedPosition(i - 1);
      if (currentUserStandings.exactScoreline === previousUserStandings.exactScoreline && currentUserStandings.goalDifference === previousUserStandings.goalDifference && currentUserStandings.outcome === previousUserStandings.outcome) {
        return { value: previousCalculatedPosition.value, label: previousCalculatedPosition.value }
      }
      return { value: previousCalculatedPosition.value + 1, label: previousCalculatedPosition.value + 1 }
    }
    return { value: i + 1, label: i + 1 }
  }

  const onApproveUser = (id) => {
    approveUserInPool(id, pool.id).then(() => {
      showAlert({ message: 'User was approved' })
    })
  }

  return (
      <div className={classes.container}>
        <TableContainer sx={{ maxHeight: ['calc(100vh - 140px)', 'calc(100vh - 140px)', 750, 750, 750] }}>
          <Table stickyHeader aria-label="simple table">
            <TableHead>
              <TableRow>
                {version !== 'basic' && <TableCell style={{ borderRight: '1px solid #ccc' }}><IoIosPodium/></TableCell>}
                {version === 'basic' && pool.ownerUserId === id && <TableCell>Approve</TableCell>}
                <TableCell>{version !== 'basic' ? 'User' : 'Spectator'}</TableCell>
                <TableCell align="right" style={{ fontWeight: 'bold', borderRight: showPredictionsPerFixture ? '1px solid #ccc' : 'none' }}>Total</TableCell>
                {!showPredictionsPerFixture ?
                  <>
                    <TableCell align="right">Exact Scoreline</TableCell>
                    <TableCell align="right">Goal Difference</TableCell>
                    <TableCell align="right">Outcome</TableCell>
                    <TableCell align="right">Top Scorer</TableCell>
                    <TableCell align="right">Champion</TableCell>
                  </>
                :
                  <>
                    {sortedFinishedFixtures.map(f =>
                      <TableCell align="right" key={`table-head-cell-${f.id}`} style={{ fontWeight: 'bold' }}>
                        {`${f.homeTeam.name.replace('FC', '').replace('RB', '').replace(' ', '').slice(0, 3)} - ${f.awayTeam.name.replace('FC', '').replace('RB', '').replace(' ', '').slice(0, 3)}`}
                        <br/>
                        {`${f.homeTeamScore} - ${f.awayTeamScore}`}
                        <div style={{ fontSize: 10 }}>{shortTimestampToDate(f.date)}</div>
                      </TableCell>
                    )}
                  </>
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {standings.sort((a, b) => b.total - a.total || b.exactScoreline - a.exactScoreline || b.goalDifference - a.goalDifference || b.outcome - a.outcome).map((row, i) => (
                <TableRow
                  key={row.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 }, background: selectedRow === i ? '#e3e3e3' : '#fff' }}
                  onClick={() => selectedRow === i ? setSelectedRow(-1) : setSelectedRow(i)}
                >
                  {version !== 'basic' &&
                    <TableCell style={{ borderRight: '1px solid #ccc', textAlign: 'center' }}>
                      {getCalculatedPosition(i).label}
                    </TableCell>
                  }
                  {version === 'basic' && pool.ownerUserId === id &&
                    <TableCell>
                      <Button onClick={() => onApproveUser(row.id)} variant="contained">Approve</Button>
                    </TableCell>
                  }
                  <TableCell component="th" scope="row" style={{ minWidth: 160 }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {row.previousResults ?
                        <Badge
                          color="primary"
                          badgeContent={<GiLaurelCrown style={{ color: '#ffffff' }}/>}
                          overlap="circular"
                          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                          sx={{ marginRight: '15px', '& .MuiBadge-badge': { width: 20, height: 20, borderRadius: '50%', border: '2px solid #ffffff', padding: 0, cursor: 'pointer' } }}
                          title={row.previousResults.map(r => `${r.position} in ${r.year}`).join('  ||  ')}
                        >
                          <Avatar src={row.photoURL} sx={{ marginRight: 0, width: 34, height: 34, border: '2px solid #3ecc51', cursor: 'pointer' }}/>
                        </Badge>
                      :
                        <Avatar src={row.photoURL} sx={{ marginRight: '15px', width: 34, height: 34, border: '2px solid transparent' }}/>
                      }
                      {row.userName}
                    </div>
                  </TableCell>
                  <TableCell align="right" style={{ fontWeight: 'bold', borderRight: showPredictionsPerFixture ? '1px solid #ccc' : 'none' }}>{row.total}</TableCell>
                  {!showPredictionsPerFixture ?
                    <>
                      <TableCell align="right" style={{ minWidth: 105 }}>{row.exactScoreline}</TableCell>
                      <TableCell align="right" style={{ minWidth: 105 }}>{row.goalDifference}</TableCell>
                      <TableCell align="right">{row.outcome}</TableCell>
                      <TableCell align="right" style={{ minWidth: 75 }}>
                        <div>
                          <div>{row.topScorer || '-'}</div>
                          <div>{row.topScorerName}</div>
                        </div>
                      </TableCell>
                      <TableCell align="right">
                        <div>
                          <div>{row.champion || '-'}</div>
                          <div>{row.championName}</div>
                        </div>
                      </TableCell>
                    </>
                  :
                    <>
                      {sortedFinishedFixtures.map(f =>
                        <TableCell align="right" style={{ minWidth: 100 }} key={`table-cell-${f.id}`}>
                          <b>{getPredictionPoints(f.userPredictions.find(p => p.userId === row.id), f, settings).total}</b>
                          <br/>
                          {f.userPredictions.find(p => p.userId === row.id) && `${f.userPredictions.find(p => p.userId === row.id).homeTeamScore} - ${f.userPredictions.find(p => p.userId === row.id).awayTeamScore}`}
                        </TableCell>
                      )}
                    </>
                  }
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
  )
}

export default StandingsTable