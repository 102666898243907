import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  palette: {
    primary: {
      main: '#10303E',
    },
    secondary: {
      main: '#ffffff',
    },
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  components: {
    MuiDialogActions: {
      styleOverrides: {
        root: {
          justifyContent: 'center',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          textAlign: 'center',
          fontSize: 30,
          fontWeight: 'bold',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          padding: '30px 0',
          margin: 20,
          width: 'calc(100% - 40px)',
          maxHeight: 'calc(100% - 40px)',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 30,
          letterSpacing: '1px',
          padding: '6px 24px',
        },
        containedSizeLarge: {
          padding: '8px 30px',
        },
        containedPrimary: {
          color: '#ffffff',
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        img: {
          borderRadius: '50%',
        },
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          textAlign: 'center',
          marginBottom: 20,
        },
      },
    },
  },
})

export default theme
