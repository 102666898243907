const firebaseConfig = {
  apiKey: 'AIzaSyD9k55CNbSgjM7mCpNgtgvggBpD8FLntsg',
  authDomain: 'wibbet-project.firebaseapp.com',
  projectId: 'wibbet-project',
  storageBucket: 'wibbet-project.appspot.com',
  messagingSenderId: '221264243990',
  appId: '1:221264243990:web:9211358818e54bafe03437',
  measurementId: 'G-WHMF045M54',
}

export default firebaseConfig
